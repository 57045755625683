* {
    box-sizing: border-box;
}

input[type=text] {
     width: 95%;
     padding: 12px;
     border: 1px solid #ccc;
     border-radius: 4px;
     resize: vertical;
 }
.currency, .currency option {
    width: 10%;
    text-size:1em;
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    resize: vertical;
}


label {
    padding: 12px 5px 12px 0;
    display: inline-block;

}

input[type=submit] {
    background-color: #332eaf;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    float: right;
}

input[type=submit]:hover {
    background-color: #332eaf;
}

.container, .NewSupplierForm {
    position:absolute;
    left:5%;
    border-radius: 5px;
    background-color: #c9c9c9;
    padding: 0;
    width:80%;

    top:1%;
    opacity:1;
    margin:auto;
    resize: both;
    overflow: auto;
    vertical-align:middle;
}

.formLabel{
    padding-left:0;
    margin:0;
    color:blue;
}

.Tubecol-25 {
    float: left;
    width: 25%;
    margin-top:20px;
    padding:0;

}

.col-25 {
    float: left;
    width: 25%;
    margin:0;
    padding:0;

}

.col-75 {
    float: left;
    left:0;
    width: 75%;
    margin:0;
    padding:0;
}

.col-75 input {
    text-align:left;
    float: left;
    width: 75%;
    margin:0;
    padding:0;
}

.row{
    padding:0;
    margin-top:20px;
}
/* Clear floats after the columns */
.row:after {
    content: "";
    display: table;
    clear: both;
}

.row-center{
    text-align:center;
    vertical-align:middle;
    padding:0;
    margin-top:20px;
}

/* Responsive layout - when the screen is less than 600px wide, make the two columns stack on top of each other instead of next to each other */
@media screen and (max-width: 600px) {
    .col-25, .col-75, input[type=submit] {
        width: 90%;
        margin: 0;
        padding:0;
    }
}

.center{
    text-align:center;
    bottom:10px;
    position:relative;
}

.buttonWrapper button {
    position:relative;
    bottom:0;
    padding: 12px 24px;
    font-size: 24px;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
    outline: none;
    color: #fff;
    background-color: #332eaf;
    border: none;
    border-radius: 15px;
    box-shadow: 0 9px #999;
}

.buttonWrapper button:hover {
    background-color: #67a718
}

.buttonWrapper button:active {
    background-color: #332eaf;
    box-shadow: 0 5px #666;
    transform: translateY(4px);
}

.mostrar-dica{
    display:none;


}
.mostrar-dica p{
    margin:15px;
}

.dica{
    bottom:0;
    left:0;
    position:absolute;
    font-size:3em;
}
.dica:hover + .mostrar-dica, .mostrar-dica:hover {
    position:absolute;
    background: #ffdc81;

    z-index:20;
    width:50%;
    border-radius:20px;
    bottom:0;
    left:0;
    display:block;
}


/* The cancel button*/

.button-cancel {

    bottom:0;
    padding: 12px 24px;
    font-size: 24px;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
    outline: none;
    color: #fff;
    background-color: #af151c;
    border: none;
    border-radius: 15px;
    box-shadow: 0 9px #999;
    margin-right:0;
}

.button-cancel:hover {background-color: #af820f
}

.button-cancel:active {
    background-color: #af151c;
    box-shadow: 0 5px #666;
    transform: translateY(4px);
}


/*The add button*/
.button-add {

    bottom:0;
    padding: 12px 24px;
    font-size: 24px;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
    outline: none;
    color: #fff;
    background-color: #568b0a;
    border: none;
    border-radius: 15px;
    box-shadow: 0 9px #999;
    margin-right:0;
}

.button-add:hover {background-color: #af820f
}

.button-add:active {
    background-color: #af151c;
    box-shadow: 0 5px #666;
    transform: translateY(4px);
}



.updating{
    position:fixed;
    left:5%;
    border-radius: 5px;
    background-color: #f2f2f2;
    padding: 5px 20px;
    width:90%;
    height:90%;
    top:5%;
    opacity:0.9;
    margin:auto;
    resize: both;
    overflow: auto;
    vertical-align:middle;
}
 img{
    position:absolute;
    max-width: 100%;
    max-height: 100%;
}

.item{
    width:50%;
    font-size:1em;
    max-height:1.5em;
    margin:0 0 2px 0;
    flex-grow:2;
    text-align:right;
}

div[class*="leftContent"] {
    text-align:left;
    z-index:1;

}
div[class*="leftContent__menu"] {


}

div[class*="leftContent__menu-list"] {

}

div[class*="leftContent__control"] {

}

div[class*="leftContent__value-container"] div {
    width:250%;
}

div[class*="leftContent__indicator"] {

}
div[class*="leftContent__dropdown-indicator"] {

}

div[class*="leftContent__placeholder"] {
    margin-left:10px;
}

div[class*="leftContent__input"] {
    margin-left:10px;
}

.row {


}

input[type='number'] {
    padding:20px;
    font-size:18px;
    width:100%;
    caret-color: red;
    display: block;
    margin:0 auto;

}

.validDimensions{
    width:50%;
    position:absolute;
    top:44%;
    left:35%;
    margin:0;
    padding:0;
    border:0;
    color: orangered;
}

.tubeUnity{
    width:25%;
    position:relative;
    left:25%;
    bottom:50px;
}

.tubeFraction{
    width:25%;
    position:relative;
    left:30%;
    bottom:65px;
}