#addClientTable{
    font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    width: 100%;
    margin:10px;
}


#addClientTable td, #addClientTable th {
    border: 1px solid #ddd;
    padding: 0;
}

#addClientTable tr:nth-child(even){background-color: #f2f2f2;}

#addClientTable tr:hover {background-color: #dda68f;}

#addClientTable th {
    padding-top: 1px;
    padding-bottom: 1px;
    text-align: left;
}

input{
    font-size:1em;
}

#addClientTitle{
    font-size:2em;
}

.toolBar{
    right:20px;
    position:absolute;
    width:55px;
    height:55px;
    float:right;
    text-align:center;
}
.icone button {
    border-radius: 20px;
    text-align:center;
}
.iconElement{
    position:absolute;
    top:0;
    left:16px;
    width:50%;
    text-align:center;
}

.iconLabel{
    position:absolute;
    text-align:center;
    font-size:0.3em;
    top:22px;
    left:16px;
}

.clientInfo{

}

.right{
    position:absolute;
    top:10%;
    height:90%;
}

#searchClientLabel{
    position:absolute;
    left:10px;
    font-size:2em;
    padding:0;
    margin:0;
}
#searchClientLabel h5{
    margin-top:10px;
    padding:0;
}