.clientSelection{
    font-size:1em;
    width:50%;
}

.transactionsOutterContainer{
    background:grey;
    opacity:0.9;
}

.currentBalance{
    float:left;
    font-weight: bold;
}
.creditAmount{
    text-align:right;
}
.debitAmount{
    text-align:right;
}