@page {
    size: A4;
    margin: 0;
}
@media print {
    html, body {
        width: 199.5mm;
        height: 282.15mm;
    }
    .quoteForPrint{
        margin: 10mm;
    }

    .quotePrintClientDetail{
        text-align:left;
    }

    /* ... the rest of the rules ... */
}

html, body {
    size: A4;
    margin: 0;
}
.quoteForPrint{
    margin: 10mm;
}

.quotePrintClientDetail{
    text-align:left;
    background:white;
    margin:0;
    padding:0;
}

.quotePrintClientDetail tbody tr{
    background:white;
    margin:0;
    padding:0;
}
.quotePrintClientDetail tbody tr td{
    border:0;
    margin:0;
    padding:0;
}

.quotePrintClientDetail tbody .label td{
    font-size:4mm;
    border-top: 2px solid #999;
    border-left:2px solid #999;
    border-right:2px solid #999;
    margin:0;
    padding:0;
}

.quotePrintClientDetail tbody .value td{
    font-size:6mm;
    border-bottom: 2px solid #999;
    border-left:2px solid #999;
    border-right:2px solid #999;
    margin:0;
    padding:0;
}

.subtotalLabel{
    color:blue;
    font-weight: bold;
    border-right:0;
}

.subtotalValue{
    border-left:0;
    color:blue;
    font-weight: bold;
}

.totalLabel{
    color: #af151c;
    font-weight: bold;
    border-right:0;
}

.totalValue{
    border-left:0;
    color: #af151c;
    font-weight: bold;
}

.finalBalance{
    text-align:right;
    font-size:8mm;
    font-weight:bold;
    color: #332eaf;
}
.authenticate{
    width:50%;

}