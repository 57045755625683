
.paymentsContainer{
    padding:0;
    margin:1px 1% 0 1%;
    border:0;
    width:85%;
    left:12%;
    text-align:left;
    position:absolute;
    opacity:1;
    background-color:grey;
}
.sidenav2 {
    height: 100%;
    width: 10%;
    position: fixed;
    z-index: 1;
    top:0;
    left: 0;
    background-color: #111;
    overflow-x: hidden;

}

.sidenav2 a {
    padding: 6px 6px 6px 1px;
    text-decoration: none;
    font-size: 20px;
    color: #818181;
    display: block;
}

.infoEnvio{
    font-size:0.75em;
}

.sidenav2 a:hover {
    color: #f1f1f1;
}

.sidenavContainer2{
    top:0;
}