.clientSelection{
    opacity:0.8;
    background:grey;
}
.clientSelection span{
    float:left;
    vertical-align:middle;
    margin:5px 10px 0 0;
    color:darkblue;
}

.clientSelectionTitle{
    font-size:1.5em;
    color:darkblue;


}

.formDetailInput2{
    position:absolute;
    top:10px;
}


div[class*="right"] {
    text-align:left;
    z-index:1;
    opacity:1;

}

.freightCompany{
    width:30%;
    margin:40px;
}

.transportChargesLabel{
    margin:40px;
}

div[class*="styles_modal"] {
    position:relative;
    width:90%;
    height:90%;
    top:10%;
    left:10%;
    margin:0;
    padding:0;
    z-index:10;
}

.dadosProduto{
    position:relative;
    top:50px;
    left:100px;
    height:50%;
    width:70%;
}

.dadosProduto input[type='number']{
    width:40%;
    position:relative;
    margin:3% 0 7% 0;
    right:0;
}
label{
    width:50%;
    margin:0;
    padding:0;
    display:inline-block;
}

.dadosProduto2 input[type='number']{
    width:50%;
    position:absolute;
    left:30%;
    top:37%;
}

.addProductButton{
    position:absolute;
    top:55%;
    left:40%;
}

.addProductSelect{
    width:90%;
    left:5%;
}
.quotePanelTransportador{
    float: left;
    text-align:left;
    padding: 10px;
    width:100%;
    background:grey;
    opacity:0.9;
    margin:20px 20px 20px 20px;
}

.labelTransportCost{
    margin:0 40px 0 40px;
}



.freightResponsible{
    margin:40px;
    font-size:1em;
}


input[type=number] {
    -moz-appearance: textfield;
    width:10%;
    margin: 40px;
    font-size:1em;
}

.summaryDiscountInput input[type=number]{
    width:100%;
}


.radioContainer {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    margin-top:10px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* Hide the browser's default radio button */
.radioContainer input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

/* Create a custom radio button */
.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #eee;
    border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.radioContainer:hover input ~ .checkmark {
    background-color: #ccc;
}

/* When the radio button is checked, add a blue background */
.radioContainer input:checked ~ .checkmark {
    background-color: #2196F3;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the indicator (dot/circle) when checked */
.radioContainer input:checked ~ .checkmark:after {
    display: block;
}

/* Style the indicator (dot/circle) */
.radioContainer .checkmark:after {
    top: 9px;
    left: 9px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: white;
}

.quoteSummary{
    background:grey;
    text-align:left;
}

.summaryDiscountInput{
    width:10%;
}

.summaryDiscountInput input:hover{
    background:orange;
}

.summaryDiscountInput input:active{
    background:red;
}

.summaryDiscountInput input:focus{
    background:lightpink;
}

.button-send {

    bottom:0;
    padding: 12px 24px;
    font-size: 24px;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
    outline: none;
    color: #fff;
    background-color: #67a718;
    border: none;
    border-radius: 15px;
    box-shadow: 0 9px #999;
    margin-right:20px;
}

.button-send:hover {background-color: #af820f
}

.button-send:active {
    background-color: #af151c;
    box-shadow: 0 5px #666;
    transform: translateY(4px);
}

.buttonWrapper{
    position:relative;
    top:8%;
}

h2{
    margin:5% 0 5% 0;
    text-align: center;
}

.summaryDiscountInput{
    width:100%;
}

.summaryDiscountInput input[type='number']{
    position:relative;
    width:20%;
    margin:0 0 0 0;
}

.summaryDiscountInput label{
   width:16%;
    margin:0 0 5% 0;
    padding: 1% 1% 0 0;
}

.quoteSummaryNote{
    color:blue;
}
.center{
    width:20%;
    text-align:center;
    left:80%;
    position:absolute;

}

.bankDetails{
    position:absolute;
    width:70%;
    margin: 2% 0 0 1.5%;
    padding:0;
    left:0;
    border: 1px solid #000000;
}

.bankDetail{
    width:30%;
    float:left;
    margin: 0;
    padding:0;
}
.bankAccountInfo{
    width:70%;
    float:right;
    margin: 0 0 0 0;
    padding:5% 0 5% 0;
    color:darkblue;
    font-family: "Palatino Linotype", "Book Antiqua", Palatino, serif;
    text-transform:uppercase;

}