.quotationItems{
    margin-top:10%;
    border-radius:15%;
}

.bar{
    position:absolute;
    background:grey;
    width:100%;
    height:10%;
    top:0;
}

.detailLink{
    border-bottom: 1px solid black;
    cursor:pointer;
}