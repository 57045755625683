.App {
  text-align: left;
  color:#000b59;
}

.App-logo {
  /* animation: App-logo-spin infinite 20s linear;*/
  height: 80px;
}


.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

@keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

body {
  margin: 0;
  padding:0;
  font-family: Arial, Helvetica, sans-serif;
  background:#737174;

}

.rio{
    z-index:2;
    top:0;
    position:absolute;
    width:100%;
    height:100%;

}

.bottomNote{
    position:absolute;
    bottom:0;
    margin:0;
    padding:0;
    background:grey;
    opacity:0.8;
    z-index:3;
    text-align:center;
    width:100%;

}

.appLoadingText, .appLoading h3, .appLoading h1{
    position:relative;
    top:0;
    z-index:3;
    color: #000000;
    text-align:center;
    opacity:1;
    background:grey;
    margin:0;
    padding:0;
}

.aerotubos{
    position:relative;
    top:0;
    z-index:3;
    color: #000000;
    text-align:center;
    opacity:1;
    background:grey;
    margin:0;
    padding:0;

}

.fornecedores{
    position:absolute;
    top:25px;
    opacity:0.85;
    width:100%;
    height:100%;
    background-color: grey;
}

.productValue p{
    text-align:left;
    margin-right:20px;
}
.clientCards{
    top:100px;
    position: relative;
    z-index:2;
}

.content{

    text-align:center;
    z-index:1;
}

.content img {
    transform: translate(-20%, -10%);
    right: 0;
    top:0;
    vertical-align:center;
    position: fixed;
    float:right;
    min-height:120%;
    min-width:200%;
    max-width:200%;
}

.clientes{
  margin:0;
  padding:0;
}
.conteudo-clientes {
  margin:0;
  padding-left:160px;
  border:0;
  left: 160px;

}
.clientes-content{
  margin-left: 160px; /* Same as the width of the sidenav */
  padding: 10px 10px;

}

.clientContainer content left{
    position: absolute;
    z-index: 9;
    background-color: #f1f1f1;
    border: 1px solid #d3d3d3;
    width:100%;
    top:0;
    margin:0px;
    font-size:1em;
    height:100%;
    opacity:0.9;
}


.searchIcon{
    font-size:2em;
    position:relative;
    vertical-align:center;

}
.searchClient {
    background:grey;
    top:50px;
    opacity:1;
    font-size:1em;
    height:200px;
    vertical-align:center;
}
input {

  margin:10px;
  background-color: white;
  -webkit-rtl-ordering: logical;
  cursor: text;
  padding: 1px;
  border: 2px inset initial;
  border-image: initial;
  height:3em;
  width:90%;
  font-size:0.5em;
}

.initial{
    text-align:center;
    z-index:1;

}
.clientList img{
    position: center;
    z-index:-1;
}

.initial img{
    position: center;
    left:0;
    z-index:-1;
}



.collapsible li{
    list-style: none;
    flex-direction: column;
    flex: 1;
    border: 1px solid gray;
    max-width:100px;
    z-index:2;
}
.collapsible li:hover{
    list-style: none;
    flex-direction: column;
    flex: 1;
    border: 1px solid gray;
    background-color: #737174;
    z-index:2;
}

.topLevel{
    border-bottom: 1px solid white;
}
.cotacao{
    opacity:0.95;
    background:grey;
    font-size:1em;

}

.clientLabel{
    color: blue;
    margin: 5px;
}
.clientValue{
    color:white;
    margin-left:10px;

}
::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
}
::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, .5);
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}

.left {
    float: left;
    width:500px;
    height:10%;
    margin-top:50px;
    margin-right:5px;
    opacity:0.9;
    color: black;

}


.left button{
    float:left;
    width:10%;
    margin:5px;
    font-size:1em;
    z-index:19;

}

div[class*="leftContent"] {
     text-align:left;
     z-index:1;

 }
div[class*="leftContent__menu"] {

}

div[class*="leftContent__menu-list"] {

}

div[class*="leftContent__control"] {
height:10px;
}

div[class*="leftContent__value-container"] div {

}

div[class*="leftContent__indicator"] {

}
div[class*="leftContent__dropdown-indicator"] {

}

div[class*="leftContent__placeholder"] {
    margin-left:10px;

}

div[class*="leftContent__input"] {
    height:10px;
    position:relative;
    left:0;
    margin:0;
    padding:0;

}
.clientDetailLabel{
    font-size:1.1em;
    color:blue;
    margin-right:10px;
    flex-grow:1;

}


hr {
    top:0;
    left:0;
    position:relative;
    clear:both;
    display:block;
    width: 96%;
    background-color:blue;
    height: 1px;
}
.left img {
    left:0;
    height:20px;
    width:20px;
    top:0;
    position:absolute;
}
.clientEdit{
    position:absolute;
    margin-top:10px;
    font-size:1em;
    margin-left:5px;
    left:0px;
    top:120px;

}
.clientNew{
    position:absolute;
    margin-top:10px;
    font-size:1em;
    margin-left:5px;
    left:0px;
    top:90px;

}

.clientEditText{
     font-size:0.75em;
     margin:5px;
     vertical-align:center;
    padding:5px;

 }
.left select:hover{
    cursor: pointer;
}
.clientEditText:hover{
    cursor: context-menu;
}

.editClientDetailsInput{
    width:50%;
    font-size:1em;
    max-height:1.5em;
    margin:0 0 2px 0;
    flex-grow:2;
    text-align:right;
}
.right span{
    box-sizing: border-box;
    position: relative;
    margin-left:15px;
    margin-right:5px;
    margin-top:20px;


}
.clientDetailLabel img{
    width:20px;
    height:20px;
    display: inline-block;
}
.right{
    right:0;
    position:absolute;
    display: block;
    overflow: auto;
    background:lightgrey;
    width:450px;
    opacity:0.95;
    height: 98%;
    bottom:10px;
    margin: 5px 5px 0 0px;
    border-radius:20px;
    padding-left:0;
    padding-right: 20px;
    border: 1px solid black;
    list-style-type: none;
    box-shadow: inset 0 0 5px blue;
    user-select: none;
    -webkit-touch-callout: none; /* iOS Safari */

    -webkit-user-select: none; /* Safari */

    -khtml-user-select: none; /* Konqueror HTML */

    -moz-user-select: none; /* Firefox */

    -ms-user-select: none; /* Internet Explorer/Edge */

    user-select: none; /* Non-prefixed version, currently

                                supported by Chrome and Opera */

}

.clientInfo{
    margin: 20px 0 0 0;
}
.right p{
    display:flex;
    margin-left:20px;
    margin-top:0;
    margin-bottom:0;
}
.right p span{
    display:flex;
    margin:5px 0 0 0;
}
.right p input{
    flex-grow:1;
    margin-top:0;
    margin-left:15px;
}
.buttonOutterWrapper{
    width:100vw;
}

.buttonWrapper button{
    text-align: center;
    position:absolute;

}
.greeting .emoji{
    font-size:3em;
    text-align:center;
}
.greeting, .greeting h3{
    margin:0;
    padding:0;
    color:white;
    text-align:left;
    font-size:2em;
}
.right button{
    background:darkblue;
    position:absolute;
    color:white;
    left:40%;
    border-radius: 10px;
    font-size:1.5em;
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
}

.appLoadingInner{
    opacity:0.9;
    background:grey;
    height:100%;
    z-index:4;
}

.appLoading {
    position: absolute;
    opacity:1;
    background:grey;
    top: 10%;
    left: 10%;
    width: 70%;
    height: 80%;
    vertical-align:center;
    text-align:center;
    display: block;
    overflow:hidden;
    margin-left: auto;
    border: 1px solid black;
    list-style-type: none;
    box-shadow: inset 0 0 5px blue;
    user-select: none;
    -webkit-touch-callout: none; /* iOS Safari */


    -webkit-user-select: none; /* Safari */

    -khtml-user-select: none; /* Konqueror HTML */

    -moz-user-select: none; /* Firefox */

    -ms-user-select: none; /* Internet Explorer/Edge */

    user-select: none; /* Non-prefixed version, currently

                                supported by Chrome and Opera */

}
.updating{
    max-height:50%;
}
.updating img{
    position: absolute;
    top: 30%;
    left: 20%;
    width: 50%;
    height: 50%;
}
.right updating {
    position: relative;
    font-size:2em;
    top: 10%;
    left: 10%;
    width: 100%;
    height: 50%;
    vertical-align:center;
    text-align:center;
    opacity:0.9;
    display: block;
    overflow: auto;
    margin-left: auto;
    border: 1px solid black;
    list-style-type: none;
    box-shadow: inset 0 0 5px blue;
    user-select: none;
    -webkit-touch-callout: none; /* iOS Safari */

    -webkit-user-select: none; /* Safari */

    -khtml-user-select: none; /* Konqueror HTML */

    -moz-user-select: none; /* Firefox */

    -ms-user-select: none; /* Internet Explorer/Edge */

    user-select: none; /* Non-prefixed version, currently

                                supported by Chrome and Opera */

}




input {
    margin-bottom: 1rem;
    display: block;
}

textarea {
    margin-bottom: 1rem;
    display: block;
}

select {
    margin-bottom: 1rem;
    display: block;
}

.icone, clientIcons{

    opacity:1;

}

.icone button, .clientIcons button{

    position:relative;
    margin-left:10px;
    padding:0;
    width:100%;
    height:100%;
    font-size:48px;
    border-radius:30px;
    background-color: #6a719c;
    opacity:1;


}

.icone button:hover, .clientIcons button:hover{
    color:white;
    background-color: darkblue;
    opacity:1;

}

.container form{

}

.iconLabel{
    font-size:0.5em;
    color:darkblue;
    opacity:0.8;
    text-align:center;
    margin-top:15px;
}
.iconLabelText{
    background:grey;
    margin-left:10px;
    font-size:3em;
}

.iconeContainer{
    margin: 0 auto;
    border-radius: 5px;
    opacity: 1;
    resize: both;
    display: table-cell;
    vertical-align: middle;
}

.conteudo {
    display: table;
    position: absolute;
    top:0;
    left:6.2%;
    /*height: -webkit-calc(100% - 100px);*/
    width: 93.9%;
    background:grey;
    z-index:2;
    opacity:0.9;
    height:100%;
    font-size:0.75em;
}

.iconeContainer:hover{

    opacity:1;
}
.toggleIconsIcon{
    position: absolute;
    border-radius:10px;
    background-color: #f1f1f1;
    border: 1px solid #d3d3d3;
    -webkit-user-drag: element;
    resize: both;
    bottom:0;
    left:0;
    margin:0;
    padding:0;
    font-size:0.75em;
    height:75px;
    opacity:0.7;
    z-index:20;
}

.toggleIconsIcon button{
    height:100%;
    border-radius:10px;
}

.toolBar{
    text-align:left;
}

.titulo{
    color: darkblue;
    text-align:center;
    top:50%;
}

.dadosProdutoCotacao{
    background-color:grey;
    opacity:0.9;
    font-size:1.5em;
}

.paragrafoDica{
    width:100%;
}

.lightbulb{
    color: #ff9c1b;
}

.lightbulb:hover{
    color: #67a718;
}

.stockTitle{
    margin-left:10px;
    position:absolute;
    left:0;
    padding:0;
    font-size:2em;
}

.stockTitle h5{
    margin:0;
    padding:0;
    margin-top:10px;
}

.newProductContainer{
    position:absolute;
    left:0;
    margin:0;
    padding:0;
    width:90%;

}

.newProductTitle{
    font-size:2em;
}

#refreshed{
    position:absolute;
    top:10%;
    left:10%;
    height:400px;
    width:800px;
    border: 1px solid black;
    margin:10px;
    background-image: url('../assets/arcos.jpg');
}

#refreshed .title{
    font-size:3em;
}

.welcomeParagraph{
    font-size:2em;

}

.productImage{
    position:absolute;
    margin-left:5vw;
    margin-top:20vh;
}

.productImage img{
    max-height: 50vh;
    max-width: 50vw;
    min-height: 50vh;
    min-width: 50vw;
    object-fit: contain;
    display:block;
}

.newSalesPriceLabel{
    font-size:1.5em;
    margin-top:10px;
}


