table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
}

td, th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
}

tr:nth-child(even) {
    background-color: #dddddd;
}

.productsContainer{

}

.addProductIcon{
    color:darkblue;
    font-size:120%;
}

.addProductIcon:hover{
    cursor:copy;
}

.trashIcon:hover{
    cursor:not-allowed;
}

/*for submitted quotes*/
.dadosProdutoCotacao table {
    font-size:0.5em;
    border-collapse: collapse;
    width: 100%;
}

.buttonWrapper2{
    text-align:center;
}

.buttonWrapper2 button{
    text-align: center;
    background:darkblue;
    color:white;
    left:40%;
    border-radius: 10px;
    font-size:1.5em;
    margin-right:20px;
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
}

.buttonWrapper2 button:hover{
    color:white;
    background-color: orangered;
    opacity:1;
}
.main .dadosProdutoCotacao td, th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 1px;
}

.main .dadosProdutoCotacao tr:nth-child(even) {
    background-color: #dddddd;
}

.openQuoteClientTable .label{
    font-size:0.75em;
}

.openQuoteClientTable .value{
    font-size:0.75em;
    color:darkblue;
}