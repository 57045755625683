.instructions{
    position: absolute;
    width:100%;
    height:100%;
    text-align:left;
    background:grey;
    opacity:0.9;
}

.instructions ul, .instructions h1{
    background:grey;
    opacity:1;
}
h1{
    margin:0;
    padding:0;
}

ul{
    margin-top:0;
}