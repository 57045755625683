@import url('https://fonts.googleapis.com/css?family=Montserrat:300,300i,400,400i,500,500i,600,700|Playfair+Display+SC:400,400i,700|Playfair+Display:400,400i,700');

html {
    box-sizing: unset;
    display: block;
    justify-content: center;


}


a {
    color: black;
}

a:hover {
    font-weight: bolder;
    color: black;
    text-decoration: none;
}

div.container {
    width: 100%;
}

.container-fluid {
    padding-right: 0;
    padding-left: 0;
    margin-right: auto;
    margin-left: auto;
    width: 100%;

}

.btn {
    display: block;
}

.btn:hover {
    font-weight: bolder;
    font-size: larger;
}

.container {
    padding: 0;
    margin: 0;
    width: 100%;

}

.App {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;

}

.nav nav-tabs {
    display: block;
    width: 80%;
}

#top-nav {
    display: inline-flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
    justify-content: space-evenly;
    background-color: #FAFAFA;
    border-bottom: 0.5px solid #D8D8D8;
    font-family: Montserrat;
    width: 100%;
    height: 3.5em;

}

#top-nav a:active {
    width: 100%;
    font-weight: bolder;
    text-decoration: none;
}

#logo-wrapper {
    font-family: "Playfair Display SC";
    font-size: 2em;
    padding: 0.3em 7em 0.3em 7em;
    color: black;
    letter-spacing: 0.1em;
}

.a#logo-wrapper:hover {
    font-weight: lighter;
}

.a#nav-link:hover {
    font-weight: lighter;
}

#nav-link {
    font-size: 0.7em;
    color: black;
    width: 100%;
    text-align: center;
}

#dd-btn {
    color: black;
    background-color: white;
    border-color: white;
}

#dd-btn :hover {
    font-weight: bold;
}

#dd-link {
    line-height: 1em;
}

#dd-item {
    font-size: 0.6em;
    line-height: 1em;
}

/* FOOTER */
#f
    /* PRODUCT GALLERY */
#prod-gallery-con {
    justify-content: space-evenly;
    padding: 60px;
    text-align: center;
    width: 100%;
}

.prod-gallery-row {

}

.prod-gallery-col {

}

#prod-frame {
    background-color: black;
    margin: 20px 50px;
    height: 150px;
    width: auto;
}

#prod-title {
    padding: 10px;
    text-align: center;
    font-family: Montserrat;
    color: black;
    font-size: 1em;
    font-weight: 400;
    margin-bottom: 70px;
}

#indv-prod-cont {

    justify-content: center;
}

#prod-gallery-wrapper {
    display: flex;
    justify-content: center;
    padding-top: 2.5em;
    width: 100%;
}

/* ACCOUNT */
#acc-blurb {
    display: block;
    justify-content: center;
    font-family: Montserrat;
    padding: 4em;
    line-height: 2em;
    font-size: 0.8em;
}

#acc-nav-wrap {

    width: 60%;
    margin: 1px 200px;


}

#acc-nav-cont {

}

#acc-nav-item {
    font-size: 0.7em;
    letter-spacing: 0.1em;
}

/* PROFILE */
#profile-wrapper {
    display: block;
    justify-content: center;
    width: 80%;
    font-size: 0.8em;
    padding: 2px;

}

#profile-style {
    width: 100%;
    background-color: white;
    display: flex;
    padding: 3em;
}

#details-hd {
    padding: 2em 2em 0.2em;
    font-family: Montserrat;
    font-weight: bolder;
    font-size: 1.2em;
}

#details-subhd {
    font-family: Montserrat;
    font-weight: 600;
    font-size: 0.9em;
    padding-bottom: 0px;
    padding-top: 10px;
}

#details-layout {
    padding: 0.2em 3em;
    font-size: 0.7em;
}


/* ORDER HISTORY */
#table-title {
    display: flex;
    justify-content: center;
    width: 80%;
    font-size: 0.7em;
    padding-top: 3em;
    text-transform: capitalize;
}

/* PRODUCT LIST */
#prod-list-con {
    display: flex;
    justify-content: center;
    width: 80%;
    font-size: 0.7em;
    padding: 40px;
}


/* CLIENT PORTAL */
#cp-styles {
    background-color: #EDEBE7;
    display: flex;
    justify-content: center;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 768px;
    align-items: center;
    box-sizing: unset;

}

#cp-btn {
    background-color: #EDEBE7;
    color: black;
    border: none;
    text-align: center;
    font-size: 2em;
    box-sizing: unset;
}

#cp-btn :hover {
    font-size: 3em;
    font-weight: bolder;
}

#cp-modal-wrapper {

    display: block;
    padding: 0;
    margin: 0;
    justify-content: center;

}

#cp-modal {
    display: flex;
}

#cp-spacer {
    margin: 2em;
    font-size: 3em;
    font-weight: lighter;

}

/* ADMIN */
#admin-page-wrapper {
    margin: 50px 150px;
}


/* ADMIN NAV BAR */
.sidenav---navtext---1AE_f {
    font-size: 1.3em;
    line-height: 1em;
    padding: 10px;
}

#admin-nav {
    background-color: black;
    color: white;
    font-size: 4em;
    position:fixed;
}

#admin-nav-titles {
    font-size: 3em;
}


#landing-pg-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 60em;
    height: 35em;
    background-color: #E2E0DD;
    margin: 5em;
    color: black;
    text-decoration: none;

}

#landing-pg-logo {
    color: black;
    font-family: "Playfair Display SC";
    font-size: 9em;
    letter-spacing: 0.1em;
    padding-bottom: 0.3em;
}

#landing-pg-nav {
    color: black;
    line-height: 2em;
    vertical-align: center;
    font-size: 1.3em;
    letter-spacing: 0.2em;
    font-family: Montserrat;
    font-weight: 300;
    padding-bottom: 3.2em;

}

#div-bar {
    font-size: 1.4em;
    font-weight: 300;
    line-height: 2em;
    vertical-align: center;
    padding: 1em;
}

.sideNavIcon{
    font-size:2em;
    z-index:5;

}


.sideNavInfo{
    position:absolute;
    left:0;
    bottom:0;
    display:none;
    padding-left:0;
    z-index:5;

}

.sideNavIcon:hover + .sideNavInfo,.sideNavInfo:hover {
    display: block;
    background:#262626;
    z-index:5;
    position:absolute;
    bottom:0;
    height:100%;
    left:18px;
    width:296px;

}
.sideNavInfo:hover {

}

.sideNavHoverText{
    font-size:1.5em;
    padding-bottom:20px;
    margin:30px;
    text-align:center;
    vertical-align:middle;
    z-index:1;

}

.subnav{
    position:absolute;
    left:46px;
    top:50px;
    z-index:5;
    opacity:1;
    width:100px;
}

.subnav .subnavItem{
    background:#262626;
    width:250px;
    text-align:left;
    padding-left:10px;
    font-size:0.75em;
}


.sideNavInfo .sideNavIcon{
    position:absolute;
    left:0;
    padding:0;
    margin:0;
}


.subnav .subnavItem:hover{

    background:green;
}
