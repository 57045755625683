body {
  background-color: #e2e0dd;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.logoMarca{
  position:relative;
  top:20px;
  left:95px;
  margin-bottom:50px;
}

.signin-box {
  background-color: #000000;
  border: 1px solid #ffffff;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  font-weight: 500;
  justify-content: center;
  padding: 20px 30px;
  width: 90%;
  height:90%;
  margin-top:10%;

}

.signin-form {
  line-height: 1em;
}

.signin-form-h1 {
  font-family: 'Montserrat', sans-serif;
  font-size: 1.75em;
  font-weight: 500;
  letter-spacing: 0.2em;
  padding-top: 75px;
  text-align: center;
  text-transform: capitalize;


}

.signin-form-h2 {
  font-family: 'Montserrat', sans-serif;
  font-size: 0.7em;
  font-weight: lighter;
  letter-spacing: 0.1em;
  line-height: 2em;
  text-align: left;
  text-transform: capitalize;
  margin-left:10px;
}

.field-wrap {
  padding-top: 0;
}

.form-input {
  width: 100%;
  padding: 12px 16px;
  text-align: left;
  border: 1px solid #1b1b1c;
  -webkit-box-shadow: inset 0 0 8px rgba(0, 0, 0, 0.1),
  0 0 16px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: inset 0 0 8px rgba(0, 0, 0, 0.1),
  0 0 16px rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 0 8px rgba(0, 0, 0, 0.1),
  0 0 16px rgba(0, 0, 0, 0.1);
}

#pw-input{
  border-radius: 5px;
  width:95%;
}

.signin-btn {
  background-color: #ffffff;
  border: 1px solid #1b1b1c;
  color: #000000;
  display: block;
  font-size: 0.8em;
  font-weight: 500;
  font-family: Montserrat, sans-serif;
  margin-left: auto;
  margin-right: auto;
  margin-top: 30px;
  padding: 10px;
  text-align: center;
  cursor:pointer;

}

.signin-btn:hover {
  background-color: #1b1b1c;
  border: 1px solid #ffffff;
  color: #ffffff;
  font-weight: 600;
}

.signin-btn:focus{
  outline: none;
}

.signin-btn::selection{
  outline: none;
  background:white;
}

.form-input:focus{
  background: #e4d49f;
}

.signin-btn:hover::selection{
  background:black;
  outline: none;
}


#failed{
  position:absolute;
  font-size:0.75em;
  color:red;
  margin:0 0 0 30px;
  padding:0;
}