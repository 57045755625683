.mostrar-dica{
    display:none;


}
.mostrar-dica p{
    margin:30px;
    width:90%;
    text-align: justify;
    text-justify: inter-word;
}

.dica{
    bottom:0;
    left:0;
    position:absolute;
    font-size:3em;
}
.dica:hover + .mostrar-dica, .mostrar-dica:hover {
    position:absolute;
    background: #ffdc81;

    z-index:20;
    width:100%;
    border-radius:20px;
    bottom:0;
    left:0;
    display:block;
}

.dateInput, .inputValue{
    width:50%;
    margin:20px 20px 0 20px;
    font-size:1.5em;
}


label {
    /* Other styling... */
    text-align: right;
    clear: both;
    float:left;
    padding:0;
    margin:15px 20px 0 20px;
    font-size:2em;
}

.inputElement .label{
    font-size:1.5em;
}

