.tabContent{
    padding:0;
    margin:0;
    border:0;

}
.sidenav {
    height: 100%;
    width: 12%;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 6%;
    background-color: #111;
    overflow-x: hidden;
    padding-top: 20px;
}

.sidenav a {
    padding: 6px 6px 6px 1px;
    text-decoration: none;
    font-size: 20px;
    color: #818181;
    display: block;

}

.infoEnvio{
    font-size:0.75em;
}

.sidenav a:hover {
    color: #f1f1f1;
}

.main {
    padding:0;
    margin:0 0 0 1%;
    border:0;
    width:85%;
    left:12%;
    text-align:left;
    position:absolute;
    opacity:0.9;


}

.openQuoteContainer{
    background-color:lightgrey;
    font-size:1.5em;
}

.visualizarCotacoes div .tabContent .sidenavContainer .main{

}

.openQuoteActions{

}

.actionContent{
    text-align:center;
    margin-top:0;
}


.actionContent button {
    margin: 15px 20px 20px;
    padding: 4px 10px;
    font-size: 24px;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
    outline: none;
    color: #fff;
    background-color: #332eaf;
    border: none;
    border-radius: 15px;
    box-shadow: 0 9px #999;
}

.actionContent button:hover {
    background-color: #af820f
}

.actionContent button:active {
    background-color: #af151c;
    box-shadow: 0 5px #666;
    transform: translateY(4px);
}

.openQuoteContainer p{
    margin:0;
}

.enviarProdutosContainer{
    position:relative;
    left:30%;
    background:lightgrey;
    margin:10px;
    width:40%;

}

.enviarProdutosContainer span{
    margin:10px;
}

.enviarProdutosContainer .label{
    text-align:center;
}

input[type='number']{
    margin:20px;
}

.enviarProdutosContainer span input{
    width:80%;
}

.inputValueNumber{
    margin:10px;
    position:relative;
}

.inputElement .label{
    font-size:0.5em;
    margin-bottom:0;
}

.inputValue{
    margin:0 0 0 20px;
}

.buttons {
    text-align:center;

}

.h4{
    text-align:center;
}

.quotationBuilderInstructions{
    text-align:left;
    margin-left:50px;
    margin-right:50px;
    padding:20px;
    background:grey;
    opacity:0.9;
}

#quoteTitle{
    position:fixed;
    margin:5px 0 0 20px;
    z-index:5;
    font-size:1.5em;
}

#selectContainer{
    position:absolute;
    top:40px;
    width:90%;
    margin-left:20px;
    height:60px;

}

#quoteDetailsContainer{
    position:absolute;
    top:80px;
    width:100%;
}


.logoSquare{
    position:absolute;
    width:70px;
    height:70px;
    top:17px;
    left:16px;
    background:grey;
    -ms-transform: rotate(45deg); /* IE 9 */
    -webkit-transform: rotate(45deg); /* Safari */
    transform: rotate(45deg);
}

.aerotubosLogo div,.aerotubosLogo div span{
    z-index:2;
    border-radius:2px;
}

.firstBar{
    background:darkblue;
    position:absolute;
    left:6px;
    top:44px;
    height:4px;
    width:14px;
}
.secondBar{
    background:darkblue;
    position:absolute;
    left:31px;
    top:44px;
    height:4px;
    width:53px;
}

.thirdBar{
    background:darkblue;
    position:absolute;
    left:6px;
    top:57px;
    height:4px;
    width:14px;
}
.fourthBar{
    background:darkblue;
    position:absolute;
    left:31px;
    top:57px;
    height:4px;
    width:53px;
}

.aerotubosLogo div:nth-child(2){
    background:darkblue;
    position:absolute;
    left:33px;
    top:9px;
    height:4px;
    width:25px;
}
.aerotubosLogo div:nth-child(3){
    background:darkblue;
    position:absolute;
    left:40px;
    top:16px;
    height:4px;
    width:25px;
}
.aerotubosLogo div:nth-child(4){
    background:darkblue;
    position:absolute;
    left:47px;
    top:23px;
    height:4px;
    width:25px;
}
.aerotubosLogo div:nth-child(5){
    background:darkblue;
    position:absolute;
    left:55px;
    top:30px;
    height:4px;
    width:25px;
}
.aerotubosLogo div:nth-child(6){
    background:darkblue;
    position:absolute;
    left:53px;
    top:37px;
    height:4px;
    width:29px;
}

.aerotubosLogo div:nth-child(8){
    background:darkblue;
    position:absolute;
    left:4px;
    top:50px;
    height:4px;
    width:89px;
}



.aerotubosLogo div:nth-child(10){
    background: darkblue;
    position:absolute;
    left:53px;
    top:64px;
    height:4px;
    width:29px;
}

.aerotubosLogo div:nth-child(14){
    background: darkblue;
    position:absolute;
    left:33px;
    top:91px;
    height:4px;
    width:25px;
}


.aerotubosLogo div:nth-child(11){
    background: darkblue;
    position:absolute;
    left:55px;
    top:71px;
    height:4px;
    width:25px;
}

.aerotubosLogo div:nth-child(12){
    background: darkblue;
    position:absolute;
    left:47px;
    top:77px;
    height:4px;
    width:25px;
}

.aerotubosLogo div:nth-child(13){
    background: darkblue;
    position:absolute;
    left:40px;
    top:84px;
    height:4px;
    width:25px;
}

.aerotubosBackground{

    width:100px;
    height:100px;
    position:absolute;
    top:-16px;
    left:-16px;
    z-index:1;
    -ms-transform: rotate(-45deg); /* IE 9 */
    -webkit-transform: rotate(-45deg); /* Safari */
    transform: rotate(-45deg);

}

.aerotubosBackground div:nth-child(1){
    position:absolute;
    background:white;
    top:10px;
    width:100px;
    height:1px;
}

.aerotubosBackground div:nth-child(2){
    position:absolute;
    background:white;
    top:17px;
    width:100px;
    height:1px;
}
.aerotubosBackground div:nth-child(3){
    position:absolute;
    background:white;
    top:24px;
    width:100px;
    height:1px;
}
.aerotubosBackground div:nth-child(4){
    position:absolute;
    background:white;
    top:31px;
    width:100px;
    height:1px;
}

.aerotubosBackground div:nth-child(5){
    position:absolute;
    background:white;
    top:38px;
    width:100px;
    height:1px;
}
.aerotubosBackground div:nth-child(6){
    position:absolute;
    background:white;
    top:45px;
    width:100px;
    height:1px;
}
.aerotubosBackground div:nth-child(7){
    position:absolute;
    background:white;
    top:51px;
    width:100px;
    height:1px;
}
.aerotubosBackground div:nth-child(8){
    position:absolute;
    background:white;
    top:58px;
    width:100px;
    height:1px;
}
.aerotubosBackground div:nth-child(9){
    position:absolute;
    background:white;
    top:65px;
    width:100px;
    height:1px;
}
.aerotubosBackground div:nth-child(10){
    position:absolute;
    background:white;
    top:72px;
    width:100px;
    height:1px;
}
.aerotubosBackground div:nth-child(11){
    position:absolute;
    background:white;
    top:79px;
    width:100px;
    height:1px;
}
.aerotubosBackground div:nth-child(12){
    position:absolute;
    background:white;
    top:86px;
    width:100px;
    height:1px;
}
.aerotubosBackground div:nth-child(13){

    position:absolute;
    background:white;
    top:92px;
    width:100px;
    height:1px;

}