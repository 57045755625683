@import url('https://fonts.googleapis.com/css?family=Cedarville Cursive');

.stickyNote h5,p{
    font-size:100%;
    font-weight:normal;
    font-family: "Comic Sans MS"
}
.stickyNote ul,li{
    list-style:none;

}
.stickyNote ul{
    overflow:hidden;

}
.stickyNote ul li a{
    text-decoration:none;
    color:#000;
    background:#ffc;
    display:block;
    padding:5px;
    -moz-box-shadow:5px 5px 7px rgba(33,33,33,1);
    -webkit-box-shadow: 5px 5px 7px rgba(33,33,33,.7);
    box-shadow: 5px 5px 7px rgba(33,33,33,.7);
    -moz-transition:-moz-transform .15s linear;
    -o-transition:-o-transform .15s linear;
    -webkit-transition:-webkit-transform .15s linear;

}

.stickyNote .addNote{
    text-decoration:none;
    color:#000;
    background:#ffc;
    display:block;
    padding:5px;
    -moz-box-shadow:5px 5px 7px rgba(33,33,33,1);
    -webkit-box-shadow: 5px 5px 7px rgba(33,33,33,.7);
    box-shadow: 5px 5px 7px rgba(33,33,33,.7);
    -moz-transition:-moz-transform .15s linear;
    -o-transition:-o-transform .15s linear;
    -webkit-transition:-webkit-transform .15s linear;
    font-family: "Cedarville Cursive", serif;

}
.stickyNote td input[type='text']{
    width:100%;
    margin:0;
}
.stickyNote .noteContainer {
}

.stickyNote .addNote{
    margin:0;
    padding:0;
}
.stickyNote input.addNote {
    display: block;
    margin:0;

}

.stickyNote .direita {

    padding:0;
    top:0;
    margin:0 auto;
}


.stickyNote ul li{

    float:left;

}
.stickyNote ul li h5{
    font-size:100%;
    font-weight:bold;
    
    font-family: "Comic Sans MS";
}
.stickyNote ul li p{
    font-size:100%;

}
.stickyNote ul li a{
    -webkit-transform: rotate(-3deg);
    -o-transform: rotate(-3deg);
    -moz-transform:rotate(-3deg);
    font-family: "Comic Sans MS";
}
.stickyNote ul li:nth-child(even) a{
    -o-transform:rotate(4deg);
    -webkit-transform:rotate(4deg);
    -moz-transform:rotate(4deg);
    position:relative;
    top:5px;
    background:#cfc;
}
.stickyNote ul li:nth-child(3n) a{
    -o-transform:rotate(-3deg);
    -webkit-transform:rotate(-3deg);
    -moz-transform:rotate(-3deg);
    position:relative;
    top:-5px;
    background:#ccf;
}
.stickyNote ul li:nth-child(5n) a{
    -o-transform:rotate(5deg);
    -webkit-transform:rotate(5deg);
    -moz-transform:rotate(5deg);
    position:relative;
    top:-10px;
}
.stickyNote ul li a:hover,ul li a:focus{
    box-shadow:10px 10px 7px rgba(0,0,0,.7);
    -moz-box-shadow:10px 10px 7px rgba(0,0,0,.7);
    -webkit-box-shadow: 10px 10px 7px rgba(0,0,0,.7);
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -o-transform: scale(1);
    position:relative;
    z-index:5;
    font-size:1em;
}
.stickyNote ol{text-align:center;}
.stickyNote ol li{display:inline;padding-right:1em;}
.stickyNote ol li a{color:#fff;}