@import url(https://fonts.googleapis.com/css?family=Montserrat:300,300i,400,400i,500,500i,600,700|Playfair+Display+SC:400,400i,700|Playfair+Display:400,400i,700);
@import url(https://fonts.googleapis.com/css?family=Cedarville Cursive);
body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

.App {
  text-align: left;
  color:#000b59;
}

.App-logo {
  /* animation: App-logo-spin infinite 20s linear;*/
  height: 80px;
}


.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

@-webkit-keyframes App-logo-spin {
  from { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  to { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

@keyframes App-logo-spin {
  from { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  to { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

body {
  margin: 0;
  padding:0;
  font-family: Arial, Helvetica, sans-serif;
  background:#737174;

}

.rio{
    z-index:2;
    top:0;
    position:absolute;
    width:100%;
    height:100%;

}

.bottomNote{
    position:absolute;
    bottom:0;
    margin:0;
    padding:0;
    background:grey;
    opacity:0.8;
    z-index:3;
    text-align:center;
    width:100%;

}

.appLoadingText, .appLoading h3, .appLoading h1{
    position:relative;
    top:0;
    z-index:3;
    color: #000000;
    text-align:center;
    opacity:1;
    background:grey;
    margin:0;
    padding:0;
}

.aerotubos{
    position:relative;
    top:0;
    z-index:3;
    color: #000000;
    text-align:center;
    opacity:1;
    background:grey;
    margin:0;
    padding:0;

}

.fornecedores{
    position:absolute;
    top:25px;
    opacity:0.85;
    width:100%;
    height:100%;
    background-color: grey;
}

.productValue p{
    text-align:left;
    margin-right:20px;
}
.clientCards{
    top:100px;
    position: relative;
    z-index:2;
}

.content{

    text-align:center;
    z-index:1;
}

.content img {
    -webkit-transform: translate(-20%, -10%);
            transform: translate(-20%, -10%);
    right: 0;
    top:0;
    vertical-align:center;
    position: fixed;
    float:right;
    min-height:120%;
    min-width:200%;
    max-width:200%;
}

.clientes{
  margin:0;
  padding:0;
}
.conteudo-clientes {
  margin:0;
  padding-left:160px;
  border:0;
  left: 160px;

}
.clientes-content{
  margin-left: 160px; /* Same as the width of the sidenav */
  padding: 10px 10px;

}

.clientContainer content left{
    position: absolute;
    z-index: 9;
    background-color: #f1f1f1;
    border: 1px solid #d3d3d3;
    width:100%;
    top:0;
    margin:0px;
    font-size:1em;
    height:100%;
    opacity:0.9;
}


.searchIcon{
    font-size:2em;
    position:relative;
    vertical-align:center;

}
.searchClient {
    background:grey;
    top:50px;
    opacity:1;
    font-size:1em;
    height:200px;
    vertical-align:center;
}
input {

  margin:10px;
  background-color: white;
  -webkit-rtl-ordering: logical;
  cursor: text;
  padding: 1px;
  border: 2px inset medium none currentColor;
  border: 2px inset initial;
  border-image: none;
  border-image: initial;
  height:3em;
  width:90%;
  font-size:0.5em;
}

.initial{
    text-align:center;
    z-index:1;

}
.clientList img{
    position: center;
    z-index:-1;
}

.initial img{
    position: center;
    left:0;
    z-index:-1;
}



.collapsible li{
    list-style: none;
    flex-direction: column;
    flex: 1 1;
    border: 1px solid gray;
    max-width:100px;
    z-index:2;
}
.collapsible li:hover{
    list-style: none;
    flex-direction: column;
    flex: 1 1;
    border: 1px solid gray;
    background-color: #737174;
    z-index:2;
}

.topLevel{
    border-bottom: 1px solid white;
}
.cotacao{
    opacity:0.95;
    background:grey;
    font-size:1em;

}

.clientLabel{
    color: blue;
    margin: 5px;
}
.clientValue{
    color:white;
    margin-left:10px;

}
::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
}
::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, .5);
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}

.left {
    float: left;
    width:500px;
    height:10%;
    margin-top:50px;
    margin-right:5px;
    opacity:0.9;
    color: black;

}


.left button{
    float:left;
    width:10%;
    margin:5px;
    font-size:1em;
    z-index:19;

}

div[class*="leftContent"] {
     text-align:left;
     z-index:1;

 }
div[class*="leftContent__menu"] {

}

div[class*="leftContent__menu-list"] {

}

div[class*="leftContent__control"] {
height:10px;
}

div[class*="leftContent__value-container"] div {

}

div[class*="leftContent__indicator"] {

}
div[class*="leftContent__dropdown-indicator"] {

}

div[class*="leftContent__placeholder"] {
    margin-left:10px;

}

div[class*="leftContent__input"] {
    height:10px;
    position:relative;
    left:0;
    margin:0;
    padding:0;

}
.clientDetailLabel{
    font-size:1.1em;
    color:blue;
    margin-right:10px;
    flex-grow:1;

}


hr {
    top:0;
    left:0;
    position:relative;
    clear:both;
    display:block;
    width: 96%;
    background-color:blue;
    height: 1px;
}
.left img {
    left:0;
    height:20px;
    width:20px;
    top:0;
    position:absolute;
}
.clientEdit{
    position:absolute;
    margin-top:10px;
    font-size:1em;
    margin-left:5px;
    left:0px;
    top:120px;

}
.clientNew{
    position:absolute;
    margin-top:10px;
    font-size:1em;
    margin-left:5px;
    left:0px;
    top:90px;

}

.clientEditText{
     font-size:0.75em;
     margin:5px;
     vertical-align:center;
    padding:5px;

 }
.left select:hover{
    cursor: pointer;
}
.clientEditText:hover{
    cursor: context-menu;
}

.editClientDetailsInput{
    width:50%;
    font-size:1em;
    max-height:1.5em;
    margin:0 0 2px 0;
    flex-grow:2;
    text-align:right;
}
.right span{
    box-sizing: border-box;
    position: relative;
    margin-left:15px;
    margin-right:5px;
    margin-top:20px;


}
.clientDetailLabel img{
    width:20px;
    height:20px;
    display: inline-block;
}
.right{
    right:0;
    position:absolute;
    display: block;
    overflow: auto;
    background:lightgrey;
    width:450px;
    opacity:0.95;
    height: 98%;
    bottom:10px;
    margin: 5px 5px 0 0px;
    border-radius:20px;
    padding-left:0;
    padding-right: 20px;
    border: 1px solid black;
    list-style-type: none;
    box-shadow: inset 0 0 5px blue;
    user-select: none;
    -webkit-touch-callout: none; /* iOS Safari */

    -webkit-user-select: none; /* Safari */ /* Konqueror HTML */

    -moz-user-select: none; /* Firefox */

    -ms-user-select: none; /* Internet Explorer/Edge */

    user-select: none; /* Non-prefixed version, currently

                                supported by Chrome and Opera */

}

.clientInfo{
    margin: 20px 0 0 0;
}
.right p{
    display:flex;
    margin-left:20px;
    margin-top:0;
    margin-bottom:0;
}
.right p span{
    display:flex;
    margin:5px 0 0 0;
}
.right p input{
    flex-grow:1;
    margin-top:0;
    margin-left:15px;
}
.buttonOutterWrapper{
    width:100vw;
}

.buttonWrapper button{
    text-align: center;
    position:absolute;

}
.greeting .emoji{
    font-size:3em;
    text-align:center;
}
.greeting, .greeting h3{
    margin:0;
    padding:0;
    color:white;
    text-align:left;
    font-size:2em;
}
.right button{
    background:darkblue;
    position:absolute;
    color:white;
    left:40%;
    border-radius: 10px;
    font-size:1.5em;
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
}

.appLoadingInner{
    opacity:0.9;
    background:grey;
    height:100%;
    z-index:4;
}

.appLoading {
    position: absolute;
    opacity:1;
    background:grey;
    top: 10%;
    left: 10%;
    width: 70%;
    height: 80%;
    vertical-align:center;
    text-align:center;
    display: block;
    overflow:hidden;
    margin-left: auto;
    border: 1px solid black;
    list-style-type: none;
    box-shadow: inset 0 0 5px blue;
    user-select: none;
    -webkit-touch-callout: none; /* iOS Safari */


    -webkit-user-select: none; /* Safari */ /* Konqueror HTML */

    -moz-user-select: none; /* Firefox */

    -ms-user-select: none; /* Internet Explorer/Edge */

    user-select: none; /* Non-prefixed version, currently

                                supported by Chrome and Opera */

}
.updating{
    max-height:50%;
}
.updating img{
    position: absolute;
    top: 30%;
    left: 20%;
    width: 50%;
    height: 50%;
}
.right updating {
    position: relative;
    font-size:2em;
    top: 10%;
    left: 10%;
    width: 100%;
    height: 50%;
    vertical-align:center;
    text-align:center;
    opacity:0.9;
    display: block;
    overflow: auto;
    margin-left: auto;
    border: 1px solid black;
    list-style-type: none;
    box-shadow: inset 0 0 5px blue;
    user-select: none;
    -webkit-touch-callout: none; /* iOS Safari */

    -webkit-user-select: none; /* Safari */ /* Konqueror HTML */

    -moz-user-select: none; /* Firefox */

    -ms-user-select: none; /* Internet Explorer/Edge */

    user-select: none; /* Non-prefixed version, currently

                                supported by Chrome and Opera */

}




input {
    margin-bottom: 1rem;
    display: block;
}

textarea {
    margin-bottom: 1rem;
    display: block;
}

select {
    margin-bottom: 1rem;
    display: block;
}

.icone, clientIcons{

    opacity:1;

}

.icone button, .clientIcons button{

    position:relative;
    margin-left:10px;
    padding:0;
    width:100%;
    height:100%;
    font-size:48px;
    border-radius:30px;
    background-color: #6a719c;
    opacity:1;


}

.icone button:hover, .clientIcons button:hover{
    color:white;
    background-color: darkblue;
    opacity:1;

}

.container form{

}

.iconLabel{
    font-size:0.5em;
    color:darkblue;
    opacity:0.8;
    text-align:center;
    margin-top:15px;
}
.iconLabelText{
    background:grey;
    margin-left:10px;
    font-size:3em;
}

.iconeContainer{
    margin: 0 auto;
    border-radius: 5px;
    opacity: 1;
    resize: both;
    display: table-cell;
    vertical-align: middle;
}

.conteudo {
    display: table;
    position: absolute;
    top:0;
    left:6.2%;
    /*height: -webkit-calc(100% - 100px);*/
    width: 93.9%;
    background:grey;
    z-index:2;
    opacity:0.9;
    height:100%;
    font-size:0.75em;
}

.iconeContainer:hover{

    opacity:1;
}
.toggleIconsIcon{
    position: absolute;
    border-radius:10px;
    background-color: #f1f1f1;
    border: 1px solid #d3d3d3;
    -webkit-user-drag: element;
    resize: both;
    bottom:0;
    left:0;
    margin:0;
    padding:0;
    font-size:0.75em;
    height:75px;
    opacity:0.7;
    z-index:20;
}

.toggleIconsIcon button{
    height:100%;
    border-radius:10px;
}

.toolBar{
    text-align:left;
}

.titulo{
    color: darkblue;
    text-align:center;
    top:50%;
}

.dadosProdutoCotacao{
    background-color:grey;
    opacity:0.9;
    font-size:1.5em;
}

.paragrafoDica{
    width:100%;
}

.lightbulb{
    color: #ff9c1b;
}

.lightbulb:hover{
    color: #67a718;
}

.stockTitle{
    margin-left:10px;
    position:absolute;
    left:0;
    padding:0;
    font-size:2em;
}

.stockTitle h5{
    margin:0;
    padding:0;
    margin-top:10px;
}

.newProductContainer{
    position:absolute;
    left:0;
    margin:0;
    padding:0;
    width:90%;

}

.newProductTitle{
    font-size:2em;
}

#refreshed{
    position:absolute;
    top:10%;
    left:10%;
    height:400px;
    width:800px;
    border: 1px solid black;
    margin:10px;
    background-image: url(/static/media/arcos.b8679275.jpg);
}

#refreshed .title{
    font-size:3em;
}

.welcomeParagraph{
    font-size:2em;

}

.productImage{
    position:absolute;
    margin-left:5vw;
    margin-top:20vh;
}

.productImage img{
    max-height: 50vh;
    max-width: 50vw;
    min-height: 50vh;
    min-width: 50vw;
    object-fit: contain;
    display:block;
}

.newSalesPriceLabel{
    font-size:1.5em;
    margin-top:10px;
}



body {
  background-color: #e2e0dd;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.logoMarca{
  position:relative;
  top:20px;
  left:95px;
  margin-bottom:50px;
}

.signin-box {
  background-color: #000000;
  border: 1px solid #ffffff;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  font-weight: 500;
  justify-content: center;
  padding: 20px 30px;
  width: 90%;
  height:90%;
  margin-top:10%;

}

.signin-form {
  line-height: 1em;
}

.signin-form-h1 {
  font-family: 'Montserrat', sans-serif;
  font-size: 1.75em;
  font-weight: 500;
  letter-spacing: 0.2em;
  padding-top: 75px;
  text-align: center;
  text-transform: capitalize;


}

.signin-form-h2 {
  font-family: 'Montserrat', sans-serif;
  font-size: 0.7em;
  font-weight: lighter;
  letter-spacing: 0.1em;
  line-height: 2em;
  text-align: left;
  text-transform: capitalize;
  margin-left:10px;
}

.field-wrap {
  padding-top: 0;
}

.form-input {
  width: 100%;
  padding: 12px 16px;
  text-align: left;
  border: 1px solid #1b1b1c;
  box-shadow: inset 0 0 8px rgba(0, 0, 0, 0.1),
  0 0 16px rgba(0, 0, 0, 0.1);
}

#pw-input{
  border-radius: 5px;
  width:95%;
}

.signin-btn {
  background-color: #ffffff;
  border: 1px solid #1b1b1c;
  color: #000000;
  display: block;
  font-size: 0.8em;
  font-weight: 500;
  font-family: Montserrat, sans-serif;
  margin-left: auto;
  margin-right: auto;
  margin-top: 30px;
  padding: 10px;
  text-align: center;
  cursor:pointer;

}

.signin-btn:hover {
  background-color: #1b1b1c;
  border: 1px solid #ffffff;
  color: #ffffff;
  font-weight: 600;
}

.signin-btn:focus{
  outline: none;
}

.signin-btn::-moz-selection{
  outline: none;
  background:white;
}

.signin-btn::selection{
  outline: none;
  background:white;
}

.form-input:focus{
  background: #e4d49f;
}

.signin-btn:hover::-moz-selection{
  background:black;
  outline: none;
}

.signin-btn:hover::selection{
  background:black;
  outline: none;
}


#failed{
  position:absolute;
  font-size:0.75em;
  color:red;
  margin:0 0 0 30px;
  padding:0;
}
.tabContent{
    padding:0;
    margin:0;
    border:0;

}
.sidenav {
    height: 100%;
    width: 12%;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 6%;
    background-color: #111;
    overflow-x: hidden;
    padding-top: 20px;
}

.sidenav a {
    padding: 6px 6px 6px 1px;
    text-decoration: none;
    font-size: 20px;
    color: #818181;
    display: block;

}

.infoEnvio{
    font-size:0.75em;
}

.sidenav a:hover {
    color: #f1f1f1;
}

.main {
    padding:0;
    margin:0 0 0 1%;
    border:0;
    width:85%;
    left:12%;
    text-align:left;
    position:absolute;
    opacity:0.9;


}

.openQuoteContainer{
    background-color:lightgrey;
    font-size:1.5em;
}

.visualizarCotacoes div .tabContent .sidenavContainer .main{

}

.openQuoteActions{

}

.actionContent{
    text-align:center;
    margin-top:0;
}


.actionContent button {
    margin: 15px 20px 20px;
    padding: 4px 10px;
    font-size: 24px;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
    outline: none;
    color: #fff;
    background-color: #332eaf;
    border: none;
    border-radius: 15px;
    box-shadow: 0 9px #999;
}

.actionContent button:hover {
    background-color: #af820f
}

.actionContent button:active {
    background-color: #af151c;
    box-shadow: 0 5px #666;
    -webkit-transform: translateY(4px);
            transform: translateY(4px);
}

.openQuoteContainer p{
    margin:0;
}

.enviarProdutosContainer{
    position:relative;
    left:30%;
    background:lightgrey;
    margin:10px;
    width:40%;

}

.enviarProdutosContainer span{
    margin:10px;
}

.enviarProdutosContainer .label{
    text-align:center;
}

input[type='number']{
    margin:20px;
}

.enviarProdutosContainer span input{
    width:80%;
}

.inputValueNumber{
    margin:10px;
    position:relative;
}

.inputElement .label{
    font-size:0.5em;
    margin-bottom:0;
}

.inputValue{
    margin:0 0 0 20px;
}

.buttons {
    text-align:center;

}

.h4{
    text-align:center;
}

.quotationBuilderInstructions{
    text-align:left;
    margin-left:50px;
    margin-right:50px;
    padding:20px;
    background:grey;
    opacity:0.9;
}

#quoteTitle{
    position:fixed;
    margin:5px 0 0 20px;
    z-index:5;
    font-size:1.5em;
}

#selectContainer{
    position:absolute;
    top:40px;
    width:90%;
    margin-left:20px;
    height:60px;

}

#quoteDetailsContainer{
    position:absolute;
    top:80px;
    width:100%;
}


.logoSquare{
    position:absolute;
    width:70px;
    height:70px;
    top:17px;
    left:16px;
    background:grey; /* IE 9 */
    -webkit-transform: rotate(45deg); /* Safari */
    transform: rotate(45deg);
}

.aerotubosLogo div,.aerotubosLogo div span{
    z-index:2;
    border-radius:2px;
}

.firstBar{
    background:darkblue;
    position:absolute;
    left:6px;
    top:44px;
    height:4px;
    width:14px;
}
.secondBar{
    background:darkblue;
    position:absolute;
    left:31px;
    top:44px;
    height:4px;
    width:53px;
}

.thirdBar{
    background:darkblue;
    position:absolute;
    left:6px;
    top:57px;
    height:4px;
    width:14px;
}
.fourthBar{
    background:darkblue;
    position:absolute;
    left:31px;
    top:57px;
    height:4px;
    width:53px;
}

.aerotubosLogo div:nth-child(2){
    background:darkblue;
    position:absolute;
    left:33px;
    top:9px;
    height:4px;
    width:25px;
}
.aerotubosLogo div:nth-child(3){
    background:darkblue;
    position:absolute;
    left:40px;
    top:16px;
    height:4px;
    width:25px;
}
.aerotubosLogo div:nth-child(4){
    background:darkblue;
    position:absolute;
    left:47px;
    top:23px;
    height:4px;
    width:25px;
}
.aerotubosLogo div:nth-child(5){
    background:darkblue;
    position:absolute;
    left:55px;
    top:30px;
    height:4px;
    width:25px;
}
.aerotubosLogo div:nth-child(6){
    background:darkblue;
    position:absolute;
    left:53px;
    top:37px;
    height:4px;
    width:29px;
}

.aerotubosLogo div:nth-child(8){
    background:darkblue;
    position:absolute;
    left:4px;
    top:50px;
    height:4px;
    width:89px;
}



.aerotubosLogo div:nth-child(10){
    background: darkblue;
    position:absolute;
    left:53px;
    top:64px;
    height:4px;
    width:29px;
}

.aerotubosLogo div:nth-child(14){
    background: darkblue;
    position:absolute;
    left:33px;
    top:91px;
    height:4px;
    width:25px;
}


.aerotubosLogo div:nth-child(11){
    background: darkblue;
    position:absolute;
    left:55px;
    top:71px;
    height:4px;
    width:25px;
}

.aerotubosLogo div:nth-child(12){
    background: darkblue;
    position:absolute;
    left:47px;
    top:77px;
    height:4px;
    width:25px;
}

.aerotubosLogo div:nth-child(13){
    background: darkblue;
    position:absolute;
    left:40px;
    top:84px;
    height:4px;
    width:25px;
}

.aerotubosBackground{

    width:100px;
    height:100px;
    position:absolute;
    top:-16px;
    left:-16px;
    z-index:1; /* IE 9 */
    -webkit-transform: rotate(-45deg); /* Safari */
    transform: rotate(-45deg);

}

.aerotubosBackground div:nth-child(1){
    position:absolute;
    background:white;
    top:10px;
    width:100px;
    height:1px;
}

.aerotubosBackground div:nth-child(2){
    position:absolute;
    background:white;
    top:17px;
    width:100px;
    height:1px;
}
.aerotubosBackground div:nth-child(3){
    position:absolute;
    background:white;
    top:24px;
    width:100px;
    height:1px;
}
.aerotubosBackground div:nth-child(4){
    position:absolute;
    background:white;
    top:31px;
    width:100px;
    height:1px;
}

.aerotubosBackground div:nth-child(5){
    position:absolute;
    background:white;
    top:38px;
    width:100px;
    height:1px;
}
.aerotubosBackground div:nth-child(6){
    position:absolute;
    background:white;
    top:45px;
    width:100px;
    height:1px;
}
.aerotubosBackground div:nth-child(7){
    position:absolute;
    background:white;
    top:51px;
    width:100px;
    height:1px;
}
.aerotubosBackground div:nth-child(8){
    position:absolute;
    background:white;
    top:58px;
    width:100px;
    height:1px;
}
.aerotubosBackground div:nth-child(9){
    position:absolute;
    background:white;
    top:65px;
    width:100px;
    height:1px;
}
.aerotubosBackground div:nth-child(10){
    position:absolute;
    background:white;
    top:72px;
    width:100px;
    height:1px;
}
.aerotubosBackground div:nth-child(11){
    position:absolute;
    background:white;
    top:79px;
    width:100px;
    height:1px;
}
.aerotubosBackground div:nth-child(12){
    position:absolute;
    background:white;
    top:86px;
    width:100px;
    height:1px;
}
.aerotubosBackground div:nth-child(13){

    position:absolute;
    background:white;
    top:92px;
    width:100px;
    height:1px;

}
* {
    box-sizing: border-box;
}

input[type=text] {
     width: 95%;
     padding: 12px;
     border: 1px solid #ccc;
     border-radius: 4px;
     resize: vertical;
 }
.currency, .currency option {
    width: 10%;
    text-size:1em;
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    resize: vertical;
}


label {
    padding: 12px 5px 12px 0;
    display: inline-block;

}

input[type=submit] {
    background-color: #332eaf;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    float: right;
}

input[type=submit]:hover {
    background-color: #332eaf;
}

.container, .NewSupplierForm {
    position:absolute;
    left:5%;
    border-radius: 5px;
    background-color: #c9c9c9;
    padding: 0;
    width:80%;

    top:1%;
    opacity:1;
    margin:auto;
    resize: both;
    overflow: auto;
    vertical-align:middle;
}

.formLabel{
    padding-left:0;
    margin:0;
    color:blue;
}

.Tubecol-25 {
    float: left;
    width: 25%;
    margin-top:20px;
    padding:0;

}

.col-25 {
    float: left;
    width: 25%;
    margin:0;
    padding:0;

}

.col-75 {
    float: left;
    left:0;
    width: 75%;
    margin:0;
    padding:0;
}

.col-75 input {
    text-align:left;
    float: left;
    width: 75%;
    margin:0;
    padding:0;
}

.row{
    padding:0;
    margin-top:20px;
}
/* Clear floats after the columns */
.row:after {
    content: "";
    display: table;
    clear: both;
}

.row-center{
    text-align:center;
    vertical-align:middle;
    padding:0;
    margin-top:20px;
}

/* Responsive layout - when the screen is less than 600px wide, make the two columns stack on top of each other instead of next to each other */
@media screen and (max-width: 600px) {
    .col-25, .col-75, input[type=submit] {
        width: 90%;
        margin: 0;
        padding:0;
    }
}

.center{
    text-align:center;
    bottom:10px;
    position:relative;
}

.buttonWrapper button {
    position:relative;
    bottom:0;
    padding: 12px 24px;
    font-size: 24px;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
    outline: none;
    color: #fff;
    background-color: #332eaf;
    border: none;
    border-radius: 15px;
    box-shadow: 0 9px #999;
}

.buttonWrapper button:hover {
    background-color: #67a718
}

.buttonWrapper button:active {
    background-color: #332eaf;
    box-shadow: 0 5px #666;
    -webkit-transform: translateY(4px);
            transform: translateY(4px);
}

.mostrar-dica{
    display:none;


}
.mostrar-dica p{
    margin:15px;
}

.dica{
    bottom:0;
    left:0;
    position:absolute;
    font-size:3em;
}
.dica:hover + .mostrar-dica, .mostrar-dica:hover {
    position:absolute;
    background: #ffdc81;

    z-index:20;
    width:50%;
    border-radius:20px;
    bottom:0;
    left:0;
    display:block;
}


/* The cancel button*/

.button-cancel {

    bottom:0;
    padding: 12px 24px;
    font-size: 24px;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
    outline: none;
    color: #fff;
    background-color: #af151c;
    border: none;
    border-radius: 15px;
    box-shadow: 0 9px #999;
    margin-right:0;
}

.button-cancel:hover {background-color: #af820f
}

.button-cancel:active {
    background-color: #af151c;
    box-shadow: 0 5px #666;
    -webkit-transform: translateY(4px);
            transform: translateY(4px);
}


/*The add button*/
.button-add {

    bottom:0;
    padding: 12px 24px;
    font-size: 24px;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
    outline: none;
    color: #fff;
    background-color: #568b0a;
    border: none;
    border-radius: 15px;
    box-shadow: 0 9px #999;
    margin-right:0;
}

.button-add:hover {background-color: #af820f
}

.button-add:active {
    background-color: #af151c;
    box-shadow: 0 5px #666;
    -webkit-transform: translateY(4px);
            transform: translateY(4px);
}



.updating{
    position:fixed;
    left:5%;
    border-radius: 5px;
    background-color: #f2f2f2;
    padding: 5px 20px;
    width:90%;
    height:90%;
    top:5%;
    opacity:0.9;
    margin:auto;
    resize: both;
    overflow: auto;
    vertical-align:middle;
}
 img{
    position:absolute;
    max-width: 100%;
    max-height: 100%;
}

.item{
    width:50%;
    font-size:1em;
    max-height:1.5em;
    margin:0 0 2px 0;
    flex-grow:2;
    text-align:right;
}

div[class*="leftContent"] {
    text-align:left;
    z-index:1;

}
div[class*="leftContent__menu"] {


}

div[class*="leftContent__menu-list"] {

}

div[class*="leftContent__control"] {

}

div[class*="leftContent__value-container"] div {
    width:250%;
}

div[class*="leftContent__indicator"] {

}
div[class*="leftContent__dropdown-indicator"] {

}

div[class*="leftContent__placeholder"] {
    margin-left:10px;
}

div[class*="leftContent__input"] {
    margin-left:10px;
}

.row {


}

input[type='number'] {
    padding:20px;
    font-size:18px;
    width:100%;
    caret-color: red;
    display: block;
    margin:0 auto;

}

.validDimensions{
    width:50%;
    position:absolute;
    top:44%;
    left:35%;
    margin:0;
    padding:0;
    border:0;
    color: orangered;
}

.tubeUnity{
    width:25%;
    position:relative;
    left:25%;
    bottom:50px;
}

.tubeFraction{
    width:25%;
    position:relative;
    left:30%;
    bottom:65px;
}
#addClientTable{
    font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    width: 100%;
    margin:10px;
}


#addClientTable td, #addClientTable th {
    border: 1px solid #ddd;
    padding: 0;
}

#addClientTable tr:nth-child(even){background-color: #f2f2f2;}

#addClientTable tr:hover {background-color: #dda68f;}

#addClientTable th {
    padding-top: 1px;
    padding-bottom: 1px;
    text-align: left;
}

input{
    font-size:1em;
}

#addClientTitle{
    font-size:2em;
}

.toolBar{
    right:20px;
    position:absolute;
    width:55px;
    height:55px;
    float:right;
    text-align:center;
}
.icone button {
    border-radius: 20px;
    text-align:center;
}
.iconElement{
    position:absolute;
    top:0;
    left:16px;
    width:50%;
    text-align:center;
}

.iconLabel{
    position:absolute;
    text-align:center;
    font-size:0.3em;
    top:22px;
    left:16px;
}

.clientInfo{

}

.right{
    position:absolute;
    top:10%;
    height:90%;
}

#searchClientLabel{
    position:absolute;
    left:10px;
    font-size:2em;
    padding:0;
    margin:0;
}
#searchClientLabel h5{
    margin-top:10px;
    padding:0;
}
.navigation{
    background-color:whitesmoke;
    opacity:1;
    box-shadow: 0 8px 16px -2px rgb(41, 41, 41);
    height:2em;
    margin:0;
    padding:0;
    z-index:10;
    position:fixed;
    width:100%;

}
.navigationTab{

    display: table-cell;
    vertical-align: middle;
    text-align: center;
    height:2em;
    z-index:10;
    background:whitesmoke;

}

.navigation ul, .navigation ul li {
    display: inline-block;
    list-style: none;
    margin: 0.05em;
    padding: 0;
    height:1em;

}

.tabsOutterContainer{

}

.navigation ul {
    text-align: center;
}

.navigation ul li {
    display: inline;
    margin-right: .75em;
}

.navigation ul li.last {
    margin-right: 0;
}
.navigation li a {

}
.navigation ul li a {
    background-color:whitesmoke;
    opacity:1;
    text-decoration: none;

}
.navigation ul li a span {
    background-color:whitesmoke;
    opacity:1;
    padding: 0 1em;
}



.inactiveTab{
    color: #7a7a7a;
}

.inactiveTab:hover{
    color: #ff5729;
}

.activeTab, .activeTab:hover{
    color: #67a718;
    border-bottom: 5px solid #67a718;
}

.tabContent{
    margin:2px 0 0 0;
    padding:0;
    top:2.5em;
    position:relative;
}

table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
}

td, th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
}

tr:nth-child(even) {
    background-color: #dddddd;
}

.productsContainer{

}

.addProductIcon{
    color:darkblue;
    font-size:120%;
}

.addProductIcon:hover{
    cursor:copy;
}

.trashIcon:hover{
    cursor:not-allowed;
}

/*for submitted quotes*/
.dadosProdutoCotacao table {
    font-size:0.5em;
    border-collapse: collapse;
    width: 100%;
}

.buttonWrapper2{
    text-align:center;
}

.buttonWrapper2 button{
    text-align: center;
    background:darkblue;
    color:white;
    left:40%;
    border-radius: 10px;
    font-size:1.5em;
    margin-right:20px;
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
}

.buttonWrapper2 button:hover{
    color:white;
    background-color: orangered;
    opacity:1;
}
.main .dadosProdutoCotacao td, th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 1px;
}

.main .dadosProdutoCotacao tr:nth-child(even) {
    background-color: #dddddd;
}

.openQuoteClientTable .label{
    font-size:0.75em;
}

.openQuoteClientTable .value{
    font-size:0.75em;
    color:darkblue;
}
.clientSelection{
    opacity:0.8;
    background:grey;
}
.clientSelection span{
    float:left;
    vertical-align:middle;
    margin:5px 10px 0 0;
    color:darkblue;
}

.clientSelectionTitle{
    font-size:1.5em;
    color:darkblue;


}

.formDetailInput2{
    position:absolute;
    top:10px;
}


div[class*="right"] {
    text-align:left;
    z-index:1;
    opacity:1;

}

.freightCompany{
    width:30%;
    margin:40px;
}

.transportChargesLabel{
    margin:40px;
}

div[class*="styles_modal"] {
    position:relative;
    width:90%;
    height:90%;
    top:10%;
    left:10%;
    margin:0;
    padding:0;
    z-index:10;
}

.dadosProduto{
    position:relative;
    top:50px;
    left:100px;
    height:50%;
    width:70%;
}

.dadosProduto input[type='number']{
    width:40%;
    position:relative;
    margin:3% 0 7% 0;
    right:0;
}
label{
    width:50%;
    margin:0;
    padding:0;
    display:inline-block;
}

.dadosProduto2 input[type='number']{
    width:50%;
    position:absolute;
    left:30%;
    top:37%;
}

.addProductButton{
    position:absolute;
    top:55%;
    left:40%;
}

.addProductSelect{
    width:90%;
    left:5%;
}
.quotePanelTransportador{
    float: left;
    text-align:left;
    padding: 10px;
    width:100%;
    background:grey;
    opacity:0.9;
    margin:20px 20px 20px 20px;
}

.labelTransportCost{
    margin:0 40px 0 40px;
}



.freightResponsible{
    margin:40px;
    font-size:1em;
}


input[type=number] {
    -moz-appearance: textfield;
    width:10%;
    margin: 40px;
    font-size:1em;
}

.summaryDiscountInput input[type=number]{
    width:100%;
}


.radioContainer {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    margin-top:10px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* Hide the browser's default radio button */
.radioContainer input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

/* Create a custom radio button */
.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #eee;
    border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.radioContainer:hover input ~ .checkmark {
    background-color: #ccc;
}

/* When the radio button is checked, add a blue background */
.radioContainer input:checked ~ .checkmark {
    background-color: #2196F3;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the indicator (dot/circle) when checked */
.radioContainer input:checked ~ .checkmark:after {
    display: block;
}

/* Style the indicator (dot/circle) */
.radioContainer .checkmark:after {
    top: 9px;
    left: 9px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: white;
}

.quoteSummary{
    background:grey;
    text-align:left;
}

.summaryDiscountInput{
    width:10%;
}

.summaryDiscountInput input:hover{
    background:orange;
}

.summaryDiscountInput input:active{
    background:red;
}

.summaryDiscountInput input:focus{
    background:lightpink;
}

.button-send {

    bottom:0;
    padding: 12px 24px;
    font-size: 24px;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
    outline: none;
    color: #fff;
    background-color: #67a718;
    border: none;
    border-radius: 15px;
    box-shadow: 0 9px #999;
    margin-right:20px;
}

.button-send:hover {background-color: #af820f
}

.button-send:active {
    background-color: #af151c;
    box-shadow: 0 5px #666;
    -webkit-transform: translateY(4px);
            transform: translateY(4px);
}

.buttonWrapper{
    position:relative;
    top:8%;
}

h2{
    margin:5% 0 5% 0;
    text-align: center;
}

.summaryDiscountInput{
    width:100%;
}

.summaryDiscountInput input[type='number']{
    position:relative;
    width:20%;
    margin:0 0 0 0;
}

.summaryDiscountInput label{
   width:16%;
    margin:0 0 5% 0;
    padding: 1% 1% 0 0;
}

.quoteSummaryNote{
    color:blue;
}
.center{
    width:20%;
    text-align:center;
    left:80%;
    position:absolute;

}

.bankDetails{
    position:absolute;
    width:70%;
    margin: 2% 0 0 1.5%;
    padding:0;
    left:0;
    border: 1px solid #000000;
}

.bankDetail{
    width:30%;
    float:left;
    margin: 0;
    padding:0;
}
.bankAccountInfo{
    width:70%;
    float:right;
    margin: 0 0 0 0;
    padding:5% 0 5% 0;
    color:darkblue;
    font-family: "Palatino Linotype", "Book Antiqua", Palatino, serif;
    text-transform:uppercase;

}
@page {
    size: A4;
    margin: 0;
}
@media print {
    html, body {
        width: 199.5mm;
        height: 282.15mm;
    }
    .quoteForPrint{
        margin: 10mm;
    }

    .quotePrintClientDetail{
        text-align:left;
    }

    /* ... the rest of the rules ... */
}

html, body {
    size: A4;
    margin: 0;
}
.quoteForPrint{
    margin: 10mm;
}

.quotePrintClientDetail{
    text-align:left;
    background:white;
    margin:0;
    padding:0;
}

.quotePrintClientDetail tbody tr{
    background:white;
    margin:0;
    padding:0;
}
.quotePrintClientDetail tbody tr td{
    border:0;
    margin:0;
    padding:0;
}

.quotePrintClientDetail tbody .label td{
    font-size:4mm;
    border-top: 2px solid #999;
    border-left:2px solid #999;
    border-right:2px solid #999;
    margin:0;
    padding:0;
}

.quotePrintClientDetail tbody .value td{
    font-size:6mm;
    border-bottom: 2px solid #999;
    border-left:2px solid #999;
    border-right:2px solid #999;
    margin:0;
    padding:0;
}

.subtotalLabel{
    color:blue;
    font-weight: bold;
    border-right:0;
}

.subtotalValue{
    border-left:0;
    color:blue;
    font-weight: bold;
}

.totalLabel{
    color: #af151c;
    font-weight: bold;
    border-right:0;
}

.totalValue{
    border-left:0;
    color: #af151c;
    font-weight: bold;
}

.finalBalance{
    text-align:right;
    font-size:8mm;
    font-weight:bold;
    color: #332eaf;
}
.authenticate{
    width:50%;

}
.mostrar-dica{
    display:none;


}
.mostrar-dica p{
    margin:30px;
    width:90%;
    text-align: justify;
    text-justify: inter-word;
}

.dica{
    bottom:0;
    left:0;
    position:absolute;
    font-size:3em;
}
.dica:hover + .mostrar-dica, .mostrar-dica:hover {
    position:absolute;
    background: #ffdc81;

    z-index:20;
    width:100%;
    border-radius:20px;
    bottom:0;
    left:0;
    display:block;
}

.dateInput, .inputValue{
    width:50%;
    margin:20px 20px 0 20px;
    font-size:1.5em;
}


label {
    /* Other styling... */
    text-align: right;
    clear: both;
    float:left;
    padding:0;
    margin:15px 20px 0 20px;
    font-size:2em;
}

.inputElement .label{
    font-size:1.5em;
}



.paymentsContainer{
    padding:0;
    margin:1px 1% 0 1%;
    border:0;
    width:85%;
    left:12%;
    text-align:left;
    position:absolute;
    opacity:1;
    background-color:grey;
}
.sidenav2 {
    height: 100%;
    width: 10%;
    position: fixed;
    z-index: 1;
    top:0;
    left: 0;
    background-color: #111;
    overflow-x: hidden;

}

.sidenav2 a {
    padding: 6px 6px 6px 1px;
    text-decoration: none;
    font-size: 20px;
    color: #818181;
    display: block;
}

.infoEnvio{
    font-size:0.75em;
}

.sidenav2 a:hover {
    color: #f1f1f1;
}

.sidenavContainer2{
    top:0;
}
.instructions{
    position: absolute;
    width:100%;
    height:100%;
    text-align:left;
    background:grey;
    opacity:0.9;
}

.instructions ul, .instructions h1{
    background:grey;
    opacity:1;
}
h1{
    margin:0;
    padding:0;
}

ul{
    margin-top:0;
}
.clientSelection{
    font-size:1em;
    width:50%;
}

.transactionsOutterContainer{
    background:grey;
    opacity:0.9;
}

.currentBalance{
    float:left;
    font-weight: bold;
}
.creditAmount{
    text-align:right;
}
.debitAmount{
    text-align:right;
}
.requestsContainer{
    background:grey;
}

.buttonWrapper3{
    text-align:center;
}
.buttonWrapper3 button{
    background: #568b0a;
    color:white;
    border-radius: 10px;
    font-size:1em;
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
}
html {
    box-sizing: unset;
    display: block;
    justify-content: center;


}


a {
    color: black;
}

a:hover {
    font-weight: bolder;
    color: black;
    text-decoration: none;
}

div.container {
    width: 100%;
}

.container-fluid {
    padding-right: 0;
    padding-left: 0;
    margin-right: auto;
    margin-left: auto;
    width: 100%;

}

.btn {
    display: block;
}

.btn:hover {
    font-weight: bolder;
    font-size: larger;
}

.container {
    padding: 0;
    margin: 0;
    width: 100%;

}

.App {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;

}

.nav nav-tabs {
    display: block;
    width: 80%;
}

#top-nav {
    display: inline-flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
    justify-content: space-evenly;
    background-color: #FAFAFA;
    border-bottom: 0.5px solid #D8D8D8;
    font-family: Montserrat;
    width: 100%;
    height: 3.5em;

}

#top-nav a:active {
    width: 100%;
    font-weight: bolder;
    text-decoration: none;
}

#logo-wrapper {
    font-family: "Playfair Display SC";
    font-size: 2em;
    padding: 0.3em 7em 0.3em 7em;
    color: black;
    letter-spacing: 0.1em;
}

.a#logo-wrapper:hover {
    font-weight: lighter;
}

.a#nav-link:hover {
    font-weight: lighter;
}

#nav-link {
    font-size: 0.7em;
    color: black;
    width: 100%;
    text-align: center;
}

#dd-btn {
    color: black;
    background-color: white;
    border-color: white;
}

#dd-btn :hover {
    font-weight: bold;
}

#dd-link {
    line-height: 1em;
}

#dd-item {
    font-size: 0.6em;
    line-height: 1em;
}

/* FOOTER */
#f
    /* PRODUCT GALLERY */
#prod-gallery-con {
    justify-content: space-evenly;
    padding: 60px;
    text-align: center;
    width: 100%;
}

.prod-gallery-row {

}

.prod-gallery-col {

}

#prod-frame {
    background-color: black;
    margin: 20px 50px;
    height: 150px;
    width: auto;
}

#prod-title {
    padding: 10px;
    text-align: center;
    font-family: Montserrat;
    color: black;
    font-size: 1em;
    font-weight: 400;
    margin-bottom: 70px;
}

#indv-prod-cont {

    justify-content: center;
}

#prod-gallery-wrapper {
    display: flex;
    justify-content: center;
    padding-top: 2.5em;
    width: 100%;
}

/* ACCOUNT */
#acc-blurb {
    display: block;
    justify-content: center;
    font-family: Montserrat;
    padding: 4em;
    line-height: 2em;
    font-size: 0.8em;
}

#acc-nav-wrap {

    width: 60%;
    margin: 1px 200px;


}

#acc-nav-cont {

}

#acc-nav-item {
    font-size: 0.7em;
    letter-spacing: 0.1em;
}

/* PROFILE */
#profile-wrapper {
    display: block;
    justify-content: center;
    width: 80%;
    font-size: 0.8em;
    padding: 2px;

}

#profile-style {
    width: 100%;
    background-color: white;
    display: flex;
    padding: 3em;
}

#details-hd {
    padding: 2em 2em 0.2em;
    font-family: Montserrat;
    font-weight: bolder;
    font-size: 1.2em;
}

#details-subhd {
    font-family: Montserrat;
    font-weight: 600;
    font-size: 0.9em;
    padding-bottom: 0px;
    padding-top: 10px;
}

#details-layout {
    padding: 0.2em 3em;
    font-size: 0.7em;
}


/* ORDER HISTORY */
#table-title {
    display: flex;
    justify-content: center;
    width: 80%;
    font-size: 0.7em;
    padding-top: 3em;
    text-transform: capitalize;
}

/* PRODUCT LIST */
#prod-list-con {
    display: flex;
    justify-content: center;
    width: 80%;
    font-size: 0.7em;
    padding: 40px;
}


/* CLIENT PORTAL */
#cp-styles {
    background-color: #EDEBE7;
    display: flex;
    justify-content: center;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 768px;
    align-items: center;
    box-sizing: unset;

}

#cp-btn {
    background-color: #EDEBE7;
    color: black;
    border: none;
    text-align: center;
    font-size: 2em;
    box-sizing: unset;
}

#cp-btn :hover {
    font-size: 3em;
    font-weight: bolder;
}

#cp-modal-wrapper {

    display: block;
    padding: 0;
    margin: 0;
    justify-content: center;

}

#cp-modal {
    display: flex;
}

#cp-spacer {
    margin: 2em;
    font-size: 3em;
    font-weight: lighter;

}

/* ADMIN */
#admin-page-wrapper {
    margin: 50px 150px;
}


/* ADMIN NAV BAR */
.sidenav---navtext---1AE_f {
    font-size: 1.3em;
    line-height: 1em;
    padding: 10px;
}

#admin-nav {
    background-color: black;
    color: white;
    font-size: 4em;
    position:fixed;
}

#admin-nav-titles {
    font-size: 3em;
}


#landing-pg-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 60em;
    height: 35em;
    background-color: #E2E0DD;
    margin: 5em;
    color: black;
    text-decoration: none;

}

#landing-pg-logo {
    color: black;
    font-family: "Playfair Display SC";
    font-size: 9em;
    letter-spacing: 0.1em;
    padding-bottom: 0.3em;
}

#landing-pg-nav {
    color: black;
    line-height: 2em;
    vertical-align: center;
    font-size: 1.3em;
    letter-spacing: 0.2em;
    font-family: Montserrat;
    font-weight: 300;
    padding-bottom: 3.2em;

}

#div-bar {
    font-size: 1.4em;
    font-weight: 300;
    line-height: 2em;
    vertical-align: center;
    padding: 1em;
}

.sideNavIcon{
    font-size:2em;
    z-index:5;

}


.sideNavInfo{
    position:absolute;
    left:0;
    bottom:0;
    display:none;
    padding-left:0;
    z-index:5;

}

.sideNavIcon:hover + .sideNavInfo,.sideNavInfo:hover {
    display: block;
    background:#262626;
    z-index:5;
    position:absolute;
    bottom:0;
    height:100%;
    left:18px;
    width:296px;

}
.sideNavInfo:hover {

}

.sideNavHoverText{
    font-size:1.5em;
    padding-bottom:20px;
    margin:30px;
    text-align:center;
    vertical-align:middle;
    z-index:1;

}

.subnav{
    position:absolute;
    left:46px;
    top:50px;
    z-index:5;
    opacity:1;
    width:100px;
}

.subnav .subnavItem{
    background:#262626;
    width:250px;
    text-align:left;
    padding-left:10px;
    font-size:0.75em;
}


.sideNavInfo .sideNavIcon{
    position:absolute;
    left:0;
    padding:0;
    margin:0;
}


.subnav .subnavItem:hover{

    background:green;
}

.stickyNote h5,p{
    font-size:100%;
    font-weight:normal;
    font-family: "Comic Sans MS"
}
.stickyNote ul,li{
    list-style:none;

}
.stickyNote ul{
    overflow:hidden;

}
.stickyNote ul li a{
    text-decoration:none;
    color:#000;
    background:#ffc;
    display:block;
    padding:5px;
    box-shadow: 5px 5px 7px rgba(33,33,33,.7);
    -moz-transition:-moz-transform .15s linear;
    -o-transition:-o-transform .15s linear;
    -webkit-transition:-webkit-transform .15s linear;

}

.stickyNote .addNote{
    text-decoration:none;
    color:#000;
    background:#ffc;
    display:block;
    padding:5px;
    box-shadow: 5px 5px 7px rgba(33,33,33,.7);
    -moz-transition:-moz-transform .15s linear;
    -o-transition:-o-transform .15s linear;
    -webkit-transition:-webkit-transform .15s linear;
    font-family: "Cedarville Cursive", serif;

}
.stickyNote td input[type='text']{
    width:100%;
    margin:0;
}
.stickyNote .noteContainer {
}

.stickyNote .addNote{
    margin:0;
    padding:0;
}
.stickyNote input.addNote {
    display: block;
    margin:0;

}

.stickyNote .direita {

    padding:0;
    top:0;
    margin:0 auto;
}


.stickyNote ul li{

    float:left;

}
.stickyNote ul li h5{
    font-size:100%;
    font-weight:bold;
    
    font-family: "Comic Sans MS";
}
.stickyNote ul li p{
    font-size:100%;

}
.stickyNote ul li a{
    -webkit-transform: rotate(-3deg);
    -o-transform: rotate(-3deg);
    -moz-transform:rotate(-3deg);
    font-family: "Comic Sans MS";
}
.stickyNote ul li:nth-child(even) a{
    -o-transform:rotate(4deg);
    -webkit-transform:rotate(4deg);
    -moz-transform:rotate(4deg);
    position:relative;
    top:5px;
    background:#cfc;
}
.stickyNote ul li:nth-child(3n) a{
    -o-transform:rotate(-3deg);
    -webkit-transform:rotate(-3deg);
    -moz-transform:rotate(-3deg);
    position:relative;
    top:-5px;
    background:#ccf;
}
.stickyNote ul li:nth-child(5n) a{
    -o-transform:rotate(5deg);
    -webkit-transform:rotate(5deg);
    -moz-transform:rotate(5deg);
    position:relative;
    top:-10px;
}
.stickyNote ul li a:hover,ul li a:focus{
    box-shadow:10px 10px 7px rgba(0,0,0,.7);
    -moz-box-shadow:10px 10px 7px rgba(0,0,0,.7);
    -webkit-box-shadow: 10px 10px 7px rgba(0,0,0,.7);
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -o-transform: scale(1);
    position:relative;
    z-index:5;
    font-size:1em;
}
.stickyNote ol{text-align:center;}
.stickyNote ol li{display:inline;padding-right:1em;}
.stickyNote ol li a{color:#fff;}
.quotationItems{
    margin-top:10%;
    border-radius:15%;
}

.bar{
    position:absolute;
    background:grey;
    width:100%;
    height:10%;
    top:0;
}

.detailLink{
    border-bottom: 1px solid black;
    cursor:pointer;
}
* {
    box-sizing: border-box;
}

input[type=text], textarea {
    width: 95%;
    height:100%;
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    resize: vertical;
}

label {
    padding: 12px 5px 12px 0;
    display: inline-block;

}

input[type=submit] {
    background-color: #332eaf;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    float: right;
}

input[type=submit]:hover {
    background-color: #332eaf;
}

.compraDeMaterial {
    position:relative;
    text-align:center;
    top:0;
    left:0;
}

.col-25 {
    float: left;
    width: 25%;
    margin:0;
    padding:0;
}

.col-75 {
    float: left;
    left:0;
    width: 75%;
    margin:0;
    padding:0;
}

.col-75 input {
    text-align:left;
    float: left;
    width: 75%;
    margin:0;
    padding:0;
}

.row{
    padding:0;
    margin-top:20px;
}
/* Clear floats after the columns */
.row:after {
    content: "";
    display: table;
    clear: both;
}

.row-center{
    text-align:center;
    vertical-align:middle;
    padding:0;
    margin-top:20px;
}

/* Responsive layout - when the screen is less than 600px wide, make the two columns stack on top of each other instead of next to each other */
@media screen and (max-width: 600px) {
    .col-25, .col-75, input[type=submit] {
        width: 90%;
        margin: 0;
        padding:0;
    }
}

.center{
    text-align:center;
    bottom:10px;
    position:relative;
}

.selected, .adicionarProduto {

    bottom:0;
    padding: 12px 24px;
    font-size: 24px;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
    outline: none;
    color: #fff;
    background-color: #84ad1f;
    border: none;
    border-radius: 15px;
    box-shadow: 0 9px #999;
    margin-right:20px;
}

.selected:hover {
    background-color: #67a718
}

.selected:active {
    background-color: #332eaf;
    box-shadow: 0 5px #666;
    -webkit-transform: translateY(4px);
            transform: translateY(4px);
}

.mostrar-dica{
    display:none;


}
.mostrar-dica p{
    margin:15px;
}

.dica{
    bottom:0;
    left:0;
    position:absolute;
    font-size:3em;
}
.dica:hover + .mostrar-dica, .mostrar-dica:hover {
    position:absolute;
    background: #ffdc81;

    z-index:20;
    width:50%;
    border-radius:20px;
    bottom:0;
    left:0;
    display:block;
}


/* The cancel button*/

.not-selected {

    bottom:0;
    padding: 12px 24px;
    font-size: 24px;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
    outline: none;
    color: #fff;
    background-color: #af151c;
    border: none;
    border-radius: 15px;
    box-shadow: 0 9px #999;
    margin-right:20px;
}

.not-selected:hover {background-color: #af820f
}

.not-selected:active {
    background-color: #af151c;
    box-shadow: 0 5px #666;
    -webkit-transform: translateY(4px);
            transform: translateY(4px);
}

.updating{
    position:fixed;
    left:5%;
    border-radius: 5px;
    background-color: #f2f2f2;
    padding: 5px 20px;
    width:90%;
    height:90%;
    top:5%;
    opacity:0.9;
    margin:auto;
    resize: both;
    overflow: auto;
    vertical-align:middle;
}
img{
    position:absolute;
    max-width: 100%;
    max-height: 100%;
}

.item{
    width:10%;
}

.button-container{

}

.icon-status-container{

}

div[class^="styles_overlay"] {
    top:0;
    margin:0;
    padding:0;
}

div[class^="styles_modal"], div[class*=" styles_modal"] {

    border-radius:15px;
    width:90%;
    top:0px;
}

.detalhesDaCompra{
    background-color: #b0b0b0;
    border-radius:15px;
    margin-top:25px;
    width:100%;
}

.dadosProduto{
    display:inline-block;
    margin:0 30px 5px 0;
    border-radius:10px;
    text-align:center;
    border:solid 1px;
    box-shadow: 10px 10px 10px 5px black;

}

.nf{
    display:inline-block;
    margin:0 30px 5px 0;
    border-radius:10px;
    text-align:center;
    border:solid 1px;
    width:25%;
    height:10%;
    box-shadow: 10px 10px 10px 5px black;

}

.nf input[type='text']{
    width:50%;
    margin-top:7%;
}

.nf input[type='date']{
    width:50%;
    margin-top:7%;
    border-radius:10px;
}

.dadosProduto input{
    text-align:center;
}
.dadosProduto select{
    width:100%;
    height:160%;
    top:0;
    font-size:1em;
    text-align:center;
}

input{

    margin:0;
    padding:0;
}

.inputBox{
    width:100%;
    margin:0;
}

.formDetailInput{
    margin:0;
    padding:0;
    font-size:1em;
    text-align:center;
    background-color: #b0b0b0;
}

.formDetailInput:focus{

    background-color: #e4ba74;
}

.moedaNovoProduto{
    padding:0;
    margin:0 10px 0 0;
}

input[type=number], select, textarea {
    margin:0;
    padding:0;
    text-align:center;
    font-size:1em;
    width:100%;
    left:0;
    border-radius:10px;

    position:relative;
}

.unidade{
    margin:10px 0 0 0;
}

.dadosProduto p {
    margin:15px;
}

.totalCompra{
    color: #af151c;
    text-align:left;
    opacity:0.8;
}

.totalCompra tr th{
    text-align:right;
}

.totalCompra tr td{
    text-align:right;
}

.emptyRow{
    opacity:1;
}

.subtotalCompra{
    background-color: #b0b0b0;
    border-radius:15px;
    margin-top:25px;
    width:100%;
}
.subtotalCompra tr th{

    text-align:right;
}

.submitPurchase{
    color:green;
    bottom:0;
    padding: 12px 24px;
    font-size: 24px;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
    outline: none;
    border: none;
    border-radius: 15px;
    box-shadow: 0 9px #999;
    margin-right:20px;
    background-color:white;
}

.submitPurchase:hover{
    color:white;
    bottom:0;
    padding: 12px 24px;
    font-size: 24px;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
    outline: none;
    border: none;
    border-radius: 15px;
    box-shadow: 0 9px #999;
    margin-right:20px;
    background-color:green;
}

.info{
    text-align:center;
    position: fixed;
    top: 50%;
    left: 50%;
    /* bring your own prefixes */
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    font-size:2em;
    color:white;
    background: #7a7a7a;
    opacity:0.9;
}

@-webkit-keyframes blink {
    /**
     * At the start of the animation the dot
     * has an opacity of .2
     */
    0% {
        opacity: .2;
    }
    /**
     * At 20% the dot is fully visible and
     * then fades out slowly
     */
    20% {
        opacity: 1;
    }
    /**
     * Until it reaches an opacity of .2 and
     * the animation can start again
     */
    100% {
        opacity: .2;
    }
}

@keyframes blink {
    /**
     * At the start of the animation the dot
     * has an opacity of .2
     */
    0% {
        opacity: .2;
    }
    /**
     * At 20% the dot is fully visible and
     * then fades out slowly
     */
    20% {
        opacity: 1;
    }
    /**
     * Until it reaches an opacity of .2 and
     * the animation can start again
     */
    100% {
        opacity: .2;
    }
}

.saving span {
    /**
     * Use the blink animation, which is defined above
     */
    -webkit-animation-name: blink;
            animation-name: blink;
    /**
     * The animation should take 1.4 seconds
     */
    -webkit-animation-duration: 1.4s;
            animation-duration: 1.4s;
    /**
     * It will repeat itself forever
     */
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
    /**
     * This makes sure that the starting style (opacity: .2)
     * of the animation is applied before the animation starts.
     * Otherwise we would see a short flash or would have
     * to set the default styling of the dots to the same
     * as the animation. Same applies for the ending styles.
     */
    -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
}

.saving span:nth-child(2) {
    /**
     * Starts the animation of the third dot
     * with a delay of .2s, otherwise all dots
     * would animate at the same time
     */
    -webkit-animation-delay: .2s;
            animation-delay: .2s;
}

.saving span:nth-child(3) {
    /**
     * Starts the animation of the third dot
     * with a delay of .4s, otherwise all dots
     * would animate at the same time
     */
    -webkit-animation-delay: .4s;
            animation-delay: .4s;
}
.info2{
    text-align:center;
}

p .iconeContainer, p .icone{
    text-align:center;
    position: fixed;

    left: 50%;
    /* bring your own prefixes */
    -webkit-transform: translate(-50%, 0);
            transform: translate(-50%, 0);
}

.purchaseAddProductButtons{
    position:absolute;
    top:400px;
}


