.navigation{
    background-color:whitesmoke;
    opacity:1;
    box-shadow: 0 8px 16px -2px rgb(41, 41, 41);
    height:2em;
    margin:0;
    padding:0;
    z-index:10;
    position:fixed;
    width:100%;

}
.navigationTab{

    display: table-cell;
    vertical-align: middle;
    text-align: center;
    height:2em;
    z-index:10;
    background:whitesmoke;

}

.navigation ul, .navigation ul li {
    display: inline-block;
    list-style: none;
    margin: 0.05em;
    padding: 0;
    height:1em;

}

.tabsOutterContainer{

}

.navigation ul {
    text-align: center;
}

.navigation ul li {
    display: inline;
    margin-right: .75em;
}

.navigation ul li.last {
    margin-right: 0;
}
.navigation li a {

}
.navigation ul li a {
    background-color:whitesmoke;
    opacity:1;
    text-decoration: none;

}
.navigation ul li a span {
    background-color:whitesmoke;
    opacity:1;
    padding: 0 1em;
}



.inactiveTab{
    color: #7a7a7a;
}

.inactiveTab:hover{
    color: #ff5729;
}

.activeTab, .activeTab:hover{
    color: #67a718;
    border-bottom: 5px solid #67a718;
}

.tabContent{
    margin:2px 0 0 0;
    padding:0;
    top:2.5em;
    position:relative;
}
