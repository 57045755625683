* {
    box-sizing: border-box;
}

input[type=text], textarea {
    width: 95%;
    height:100%;
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    resize: vertical;
}

label {
    padding: 12px 5px 12px 0;
    display: inline-block;

}

input[type=submit] {
    background-color: #332eaf;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    float: right;
}

input[type=submit]:hover {
    background-color: #332eaf;
}

.compraDeMaterial {
    position:relative;
    text-align:center;
    top:0;
    left:0;
}

.col-25 {
    float: left;
    width: 25%;
    margin:0;
    padding:0;
}

.col-75 {
    float: left;
    left:0;
    width: 75%;
    margin:0;
    padding:0;
}

.col-75 input {
    text-align:left;
    float: left;
    width: 75%;
    margin:0;
    padding:0;
}

.row{
    padding:0;
    margin-top:20px;
}
/* Clear floats after the columns */
.row:after {
    content: "";
    display: table;
    clear: both;
}

.row-center{
    text-align:center;
    vertical-align:middle;
    padding:0;
    margin-top:20px;
}

/* Responsive layout - when the screen is less than 600px wide, make the two columns stack on top of each other instead of next to each other */
@media screen and (max-width: 600px) {
    .col-25, .col-75, input[type=submit] {
        width: 90%;
        margin: 0;
        padding:0;
    }
}

.center{
    text-align:center;
    bottom:10px;
    position:relative;
}

.selected, .adicionarProduto {

    bottom:0;
    padding: 12px 24px;
    font-size: 24px;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
    outline: none;
    color: #fff;
    background-color: #84ad1f;
    border: none;
    border-radius: 15px;
    box-shadow: 0 9px #999;
    margin-right:20px;
}

.selected:hover {
    background-color: #67a718
}

.selected:active {
    background-color: #332eaf;
    box-shadow: 0 5px #666;
    transform: translateY(4px);
}

.mostrar-dica{
    display:none;


}
.mostrar-dica p{
    margin:15px;
}

.dica{
    bottom:0;
    left:0;
    position:absolute;
    font-size:3em;
}
.dica:hover + .mostrar-dica, .mostrar-dica:hover {
    position:absolute;
    background: #ffdc81;

    z-index:20;
    width:50%;
    border-radius:20px;
    bottom:0;
    left:0;
    display:block;
}


/* The cancel button*/

.not-selected {

    bottom:0;
    padding: 12px 24px;
    font-size: 24px;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
    outline: none;
    color: #fff;
    background-color: #af151c;
    border: none;
    border-radius: 15px;
    box-shadow: 0 9px #999;
    margin-right:20px;
}

.not-selected:hover {background-color: #af820f
}

.not-selected:active {
    background-color: #af151c;
    box-shadow: 0 5px #666;
    transform: translateY(4px);
}

.updating{
    position:fixed;
    left:5%;
    border-radius: 5px;
    background-color: #f2f2f2;
    padding: 5px 20px;
    width:90%;
    height:90%;
    top:5%;
    opacity:0.9;
    margin:auto;
    resize: both;
    overflow: auto;
    vertical-align:middle;
}
img{
    position:absolute;
    max-width: 100%;
    max-height: 100%;
}

.item{
    width:10%;
}

.button-container{

}

.icon-status-container{

}

div[class^="styles_overlay"] {
    top:0;
    margin:0;
    padding:0;
}

div[class^="styles_modal"], div[class*=" styles_modal"] {

    border-radius:15px;
    width:90%;
    top:0px;
}

.detalhesDaCompra{
    background-color: #b0b0b0;
    border-radius:15px;
    margin-top:25px;
    width:100%;
}

.dadosProduto{
    display:inline-block;
    margin:0 30px 5px 0;
    border-radius:10px;
    text-align:center;
    border:solid 1px;
    box-shadow: 10px 10px 10px 5px black;

}

.nf{
    display:inline-block;
    margin:0 30px 5px 0;
    border-radius:10px;
    text-align:center;
    border:solid 1px;
    width:25%;
    height:10%;
    box-shadow: 10px 10px 10px 5px black;

}

.nf input[type='text']{
    width:50%;
    margin-top:7%;
}

.nf input[type='date']{
    width:50%;
    margin-top:7%;
    border-radius:10px;
}

.dadosProduto input{
    text-align:center;
}
.dadosProduto select{
    width:100%;
    height:160%;
    top:0;
    font-size:1em;
    text-align:center;
}

input{

    margin:0;
    padding:0;
}

.inputBox{
    width:100%;
    margin:0;
}

.formDetailInput{
    margin:0;
    padding:0;
    font-size:1em;
    text-align:center;
    background-color: #b0b0b0;
}

.formDetailInput:focus{

    background-color: #e4ba74;
}

.moedaNovoProduto{
    padding:0;
    margin:0 10px 0 0;
}

input[type=number], select, textarea {
    margin:0;
    padding:0;
    text-align:center;
    font-size:1em;
    width:100%;
    left:0;
    border-radius:10px;

    position:relative;
}

.unidade{
    margin:10px 0 0 0;
}

.dadosProduto p {
    margin:15px;
}

.totalCompra{
    color: #af151c;
    text-align:left;
    opacity:0.8;
}

.totalCompra tr th{
    text-align:right;
}

.totalCompra tr td{
    text-align:right;
}

.emptyRow{
    opacity:1;
}

.subtotalCompra{
    background-color: #b0b0b0;
    border-radius:15px;
    margin-top:25px;
    width:100%;
}
.subtotalCompra tr th{

    text-align:right;
}

.submitPurchase{
    color:green;
    bottom:0;
    padding: 12px 24px;
    font-size: 24px;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
    outline: none;
    border: none;
    border-radius: 15px;
    box-shadow: 0 9px #999;
    margin-right:20px;
    background-color:white;
}

.submitPurchase:hover{
    color:white;
    bottom:0;
    padding: 12px 24px;
    font-size: 24px;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
    outline: none;
    border: none;
    border-radius: 15px;
    box-shadow: 0 9px #999;
    margin-right:20px;
    background-color:green;
}

.info{
    text-align:center;
    position: fixed;
    top: 50%;
    left: 50%;
    /* bring your own prefixes */
    transform: translate(-50%, -50%);
    font-size:2em;
    color:white;
    background: #7a7a7a;
    opacity:0.9;
}

@keyframes blink {
    /**
     * At the start of the animation the dot
     * has an opacity of .2
     */
    0% {
        opacity: .2;
    }
    /**
     * At 20% the dot is fully visible and
     * then fades out slowly
     */
    20% {
        opacity: 1;
    }
    /**
     * Until it reaches an opacity of .2 and
     * the animation can start again
     */
    100% {
        opacity: .2;
    }
}

.saving span {
    /**
     * Use the blink animation, which is defined above
     */
    animation-name: blink;
    /**
     * The animation should take 1.4 seconds
     */
    animation-duration: 1.4s;
    /**
     * It will repeat itself forever
     */
    animation-iteration-count: infinite;
    /**
     * This makes sure that the starting style (opacity: .2)
     * of the animation is applied before the animation starts.
     * Otherwise we would see a short flash or would have
     * to set the default styling of the dots to the same
     * as the animation. Same applies for the ending styles.
     */
    animation-fill-mode: both;
}

.saving span:nth-child(2) {
    /**
     * Starts the animation of the third dot
     * with a delay of .2s, otherwise all dots
     * would animate at the same time
     */
    animation-delay: .2s;
}

.saving span:nth-child(3) {
    /**
     * Starts the animation of the third dot
     * with a delay of .4s, otherwise all dots
     * would animate at the same time
     */
    animation-delay: .4s;
}
.info2{
    text-align:center;
}

p .iconeContainer, p .icone{
    text-align:center;
    position: fixed;

    left: 50%;
    /* bring your own prefixes */
    transform: translate(-50%, 0);
}

.purchaseAddProductButtons{
    position:absolute;
    top:400px;
}

